/**
 * @file   src\
 * @brief  This file is responsible for
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */
import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Row, Tabs, Tab, Form, Modal } from "react-bootstrap";
import InnerLayout from "../components/InnerLayout";
import Strings from "../assets/strings/Strings.json";
import Plan from "../components/Plan";
import Input from "../components/Input";
import { useNavigate, Link, useLocation } from "react-router-dom";
import CustTextArea from "../components/CustTextArea";
import VerifiedAcc from "../assets/img/acc-verified.svg";
import PendingAcc from "../assets/img/acc-pending.svg";
import WebLink from "../components/WebLink";
import FileUpload from "../components/InputFile";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { OverlayTrigger } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Tooltip from 'react-bootstrap/Tooltip';
import AddProxy from "../assets/img/icons/AddProxy";
import { nameValidation, checkHttpsLink } from '../helpers/authHelper';
import axios from 'axios';
import { SubscriptionPlans } from '../utils/Enum'
import Loader from '../components/Loader'

import {
  _vendorProfile,
  _vendorProfileBasicEdit,
  _vendorProfileBankEdit,
  _vendorProfileBusinessEdit,
  _vendorImagePreSignedURL,
  _removeBusinessImage,
  _vendorStripeVerify,
  _addSubscription,
  _vendorCancelSubscription,
  _vendorSubscriptionDetails,
  _vendorSubscriptionHistory,
  _vendorUpgradeSubscription,
  _vendorListPaymentCard,
  _vendorDeletePaymentCard,
  _vendorBankDetails,
  _addProxyAddress,
  _viewProxyAddress,
  _profileBusinessInfo,
  _profileBankInfo,
  _profileSubscriptionInfo,
  _DeleteBankInfo,
  _removeSubscription,
  _vendorPromotionalCitiesList
} from "../actions/VendorAction";
import {
  validateFileTypes,
  phoneNumberValidation,
} from "../helpers/authHelper";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import * as alerts from "../utils/Messages";
import CardForm from "../components/CardForm";
import PlanSubscribed from "../components/PlanSubscribed";
import PlanCancel from "../components/PlanCancel";
import * as constants from '../utils/constants';

import RemoveProxy from "../assets/img/icons/RemoveProxy";
import Autocomplete from "react-google-autocomplete";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  const appearance = {
    rules: {
      '.Input': {
        border: '1px solid #BEDBEF',
        borderRadius: '7px',
        padding: '15px 13px',
        fontSize: '15px',
        margin: '0 0 8px 0'
      },
      '.Input::placeholder': {
        color: '#9E9E9E'
      },
      '.Input:focus': {
        boxShadow: 'none'
      },
      '.Label': {
        color: '#5B5B5B',
        fontSize: '13px',
        margin: "0 0 6px 0"
      },
    }

  };
  // ------- call API from functions and response from redux start ---------
  // vendor profile start
  const vendorProfile = () => dispatch(_vendorProfile());
  const vendorProfileResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorProfileResponse
  );
  // vendor profile ends
  // vendor profile basic edit start
  const editBasicInfo = (basicInfo) =>
    dispatch(_vendorProfileBasicEdit(basicInfo));
  const editBasicInfoResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorEditBasicInfoResponse
  );
  // vendor profile basic edit ends

  // vendor profile bank edit start
  const editBankInfo = (bankInfo) => dispatch(_vendorProfileBankEdit(bankInfo));
  const editBankInfoResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorEditBankInfoResponse
  );
  // vendor profile bank edit ends

  // vendor profile business edit start
  const editBusinessInfo = (businessInfo) =>
    dispatch(_vendorProfileBusinessEdit(businessInfo));
  const editBusinessInfoResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorEditBusinessInfoResponse
  );
  // vendor profile bank edit ends

  // vendor profile business image upload start
  const imagePreSignedUpload = (imageName) =>
    dispatch(_vendorImagePreSignedURL(imageName));
  const imagePreSignedUploadResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorImagePreSignedResponse
  );
  // vendor profile bank edit ends

  // vendor profile business image upload start
  const removeBusinessImage = (image) => dispatch(_removeBusinessImage(image));
  const removeBusinessImageResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorBusinessImageRemoveResponse
  );
  // vendor profile bank edit ends

  // vendor profile business image upload start
  const stripeVerify = () => dispatch(_vendorStripeVerify());
  const stripeVerifyResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorStripeVerifyResponse
  );
  // vendor profile bank edit ends

  // Add subscription response
  const addSubscription = (params) => dispatch(_addSubscription(params));
  const addSubscriptionApiResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.addSubscriptionResponse
  );

  // Remove subscription response
  const removeSubscription = (params) => dispatch(_removeSubscription(params));
  const removeSubscriptionApiResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.removeSubscriptionResponse
  );

  // Cancel subscription response
  const cancelSubscription = () => dispatch(_vendorCancelSubscription());
  const vendorCancelSubscriptionResponse = useSelector((RootReducer) => RootReducer.VendorReducer.vendorCancelSubscriptionResponse);


  const vendorSubscriptionDetails = () => dispatch(_vendorSubscriptionDetails());
  const vendorSubscriptionDetailsResponse = useSelector((RootReducer) => RootReducer.VendorReducer.vendorSubscriptionDetailsResponse);

  const vendorSubscriptionHistory = () => dispatch(_vendorSubscriptionHistory());
  const vendorSubscriptionHistoryResponse = useSelector((RootReducer) => RootReducer.VendorReducer.vendorSubscriptionHistoryResponse);


  const vendorSubscriptionUpgrade = () => dispatch(_vendorUpgradeSubscription());
  const vendorSubscriptionUpgradeResponse = useSelector((RootReducer) => RootReducer.VendorReducer.vendorSubscriptionUpgradeResponse);
  // list payment card response
  const listPaymentCard = () => dispatch(_vendorListPaymentCard());
  const listPaymentCardResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorListPaymentCardResponse
  );

  // list payment card response
  const deltePaymentCard = (params) =>
    dispatch(_vendorDeletePaymentCard(params));
  const deletePaymentCardResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorDeletePaymentCardResponse
  );

  // vendor Bank Details start
  const vendorBankDetails = () => dispatch(_vendorBankDetails());
  const vendorBankDetailsResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorBankDetailsResponse
  );
  // vendor Bank Details ends

  // vendor Bank Details start
  const addProxyAddress = (params) => dispatch(_addProxyAddress(params));
  const addProxyAddressResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.addProxyApiResponse
  );
  // vendor Bank Details ends

  // vendor Bank Details start
  const viewProxyAddress = () => dispatch(_viewProxyAddress());
  const viewProxyAddressResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.viewProxyApiResponse
  );
  // vendor Bank Details ends

  // vendor Business info Details start
  const businessInfo = () => dispatch(_profileBusinessInfo());
  const businessInfoResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.businessInfoApiResponse
  );
  // vendor Business info Details ends

  // vendor Bank info Details start
  const bankInfo = () => dispatch(_profileBankInfo());
  const bankInfoResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.bankInfoApiResponse
  );
  // vendor Bank info Details ends

  // vendor subscription info Details start
  const subscriptionInfo = () => dispatch(_profileSubscriptionInfo());
  const subscriptionInfoResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.subscriptionInfoApiResponse
  );

  const deleteBankInfo = () => dispatch(_DeleteBankInfo());
  const deleteBankinfoResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.deleteBankinfoResponse
  );
  // vendor Bank info Details ends

  const vendorPromotionalCities = () => dispatch(_vendorPromotionalCitiesList());
  const vendorPromotionalCitiesResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.promotionalCitiesResponse
  );

  const createSubscriptionResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorCreateSubscriptionResponse
  );

  const upgradeSubscriptionResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorSubscriptionUpgradeResponse
  );

  const downgradeSubscriptionResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.downgradeSubscriptionApiResponse
  );

  const expireSubscriptionResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.expireSubscriptionApiResponse
  );

  const savePaymentCardResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorSavePaymentCardResponse
  );

  // ------- call API from functions and response from redux start ---------
  // ------- state start -------
  const intitalValuesBasicInfo = {
    f_name: "",
    l_name: "",
    phone_number: "",
    pwd: "",
    e_address: "",
  };
  const intitalValuesBusinessInfo = {
    business_name: "",
    business_number: "",
    business_address: "",
    business_address2: "",
    business_city: "",
    business_state: "",
    zip_code: "",
    business_country: "",
    business_description: "",
    about: "",
    site_link: "",
    find_us_on: "",
    review_url: "",
    display_name: "",
    business_images: "",
    longitude: "",
    latitude: "",
    business_sellerpolicy_text: "",
    business_sellerpolicy_link: "",
    business_is_sellerpolicy: 0
  };
  const intitalValuesBankInfo = {
    acc_type: "",
    acc_name: "",
    routing_no: "",
    acc_number: "",
    re_acc_number: "",
  };

  const intitalValuesSubscriptionInfo = {
    subscriptionId: "",
    subscription_type: "",
    amount: "",
    start_date: "",
    end_date: "",
  };

  const intitalValuesProxyAddress = {
    address: "",
    city: "",
    state: "",
    zip: "",
    country_id: "",
    country_name: "",
    country_code: "",
    lat: "",
    long: ""
  };

  const intitalValuesProxyAddressError = {
    addressError: "",
    cityError: "",
    stateError: "",
    zipError: "",
    country_idError: "",
    country_nameError: "",
    country_codeError: "",
  };

  const intitalFormErrorBasicInfo = {
    u_idError: "",
    f_nameError: "",
    l_nameError: "",
    phone_numberError: "",
    pwdError: "",
    e_addressError: "",
  };
  const intitalFormErrorBusinessInfo = {
    business_nameError: "",
    business_numberError: "",
    business_addressError: "",
    business_address2Error: "",
    business_cityError: "",
    business_stateError: "",
    zip_codeError: "",
    business_countryError: "",
    business_descriptionError: "",
    aboutError: "",
    site_linkError: "",
    site_textError: "",
    display_nameError: "",
    review_urlError: "",
    find_us_onError: "",
    web_LinkError: ''
  };
  const intitalFormErrorBankInfo = {
    acc_typeError: "",
    acc_nameError: "",
    routing_noError: "",
    acc_numberError: "",
    re_acc_numberError: "",
  };

  const [basicInfoData, setBasicInfoData] = useState(intitalValuesBasicInfo);
  const [basicInfoError, setBasicInfoError] = useState(
    intitalFormErrorBasicInfo
  );

  const [businessInfoData, setBusinessInfoData] = useState(
    intitalValuesBusinessInfo
  );
  const [businessInfoError, setBusinessInfoError] = useState(
    intitalFormErrorBusinessInfo
  );

  const [proxyInfoData, setProxyInfoData] = useState(
    intitalValuesProxyAddress
  );
  const [proxyInfoDataResponse, setProxyInfoDataResposne] = useState(
    intitalValuesProxyAddress
  );
  const [proxyInfoError, setProxyInfoError] = useState(
    intitalValuesProxyAddressError
  );

  const [bankInfoData, setBankInfoData] = useState(intitalValuesBankInfo);
  const [bankInfoError, setBankInfoError] = useState(intitalFormErrorBankInfo);

  const [subscriptionInfoData, setSubscriptionInfoData] = useState(
    intitalValuesSubscriptionInfo
  );
  const [subscriptionListData, setSubscriptionListData] = useState(null);

  const [accountType, setAccountType] = useState(false);
  const [cardSave, setCardSave] = useState(false);
  const [editable, setEditable] = useState(false);
  const [editableBank, setEditableBank] = useState(false);
  const [editableBusiness, setEditableBusiness] = useState(false);
  const [editableProxy, setEditableProxy] = useState(false);
  const [show, setShow] = useState(false);
  const [personalAccount, setPersonalAccount] = useState(intitalValuesBankInfo);
  const [businessAccount, setBusinessAccount] = useState(intitalValuesBankInfo);
  const [bankData, setBankData] = useState(intitalValuesBankInfo);
  const [vendorId, setVendorId] = useState(null);
  const [uploadPicError, setUploadPicError] = useState("");
  const [uploadPic, setUploadPic] = useState(false);
  const [businessImage, setbusinessImage] = useState([]);
  const [businessInfoPhoneNumber, setBusinessInfoPhoneNumber] = useState("");
  const [basicInfoPhoneNumber, setBasicInfoPhoneNumber] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [loadMap, setLoadMap] = useState(false);
  const [place, setPlace] = useState(null);
  const [placeProxy, setPlaceProxy] = useState(null);
  const [currentTab, setCurrentTab] = useState("current");
  const [isVerified, setIsVerified] = useState("");
  const [planData, setPlanData] = useState({
    plan_id: "",
    amount: "",
    is_upgrade: "",
    lookupKey:"",
  });
  const [ipAddress, setIpAddress] = useState("");
  const [verifiedMessage, setVerifiedMessage] = useState("")
  const [cancelSubscriptionClicked, setCancelSubscriptionClicked] = useState(false)
  const [subscriptionDetails, setSubscriptionDetails] = useState("")
  const [subscriptionHistory, setSubscriptionHistory] = useState([])
  const [showcancelSubscription, setShowcancelSubscription] = useState(false)
  const [upgradePlanClicked, setUpgradePlanClicked] = useState(false)
  const [cardDetails, setCardDetails] = useState("");
  const [stripeVerified, setStripeVerified] = useState("")
  const [showStripe, setShowStripe] = useState(false)
  const [stripeLoading, setStripeLoading] = useState(false)
  const [showDeletePayment, setShowDeletePayment] = useState(false)
  const [showSubscriptionHistory, setShowSubscriptionHistory] = useState(false)
  const [zipcodes, setZipcodes] = useState([""])
  const [zipcodeLimit, setZipcodeLimit] = useState('')
  const [zipcodesError, setZipcodesError] = useState([""])
  const [currentPlan, setCurrentPlan] = useState(0)
  const [proxyType, setProxyType] = useState(1)
  const [proxyZipLimit, setProxyZipLimit] = useState(false)
  const [zipCodeDataResponse, setZipCodeDataResponse] = useState([""])
  const [deletePinCode, setDeletePincode] = useState(false)
  const [zipCodeDeleteIndex, setZipCodeDeleteIndex] = useState('')
  const [deleteBankinfoClicked, setDeleteBankinfoClicked] = useState(false)
  const [deleteBankinfoPopUp, setDeleteBankinfoPopUp] = useState(false)
  const [zipLimit,setZiplimit]= useState('');


  const [loadingModal, setLoadingModal] = useState(false)
  const [subscribed, setSubscribed] = useState(false)
  const [firstSubscribed, setFirstSubscribed] = useState(false)

  const [sellerPolicyType, setSellerPolicyType] = useState(false)
  const [sellerPolicy, setSellerPolicy] = useState(0)
  const [showDelete, setShowDelete] = useState(false)
  const [imageDeleteId, setImageDeleteId] = useState('')
  const [isPromotional, setIsPromotional] = useState(0)
  const [promotionalCities, setPromotionalCities] = useState([])
  const [showRemoveSubscription, setShowRemoveSubscription] = useState(false)
  const refAddress = useRef(null);
  const refAddressProxy = useRef(null);
  const refProxyZipcode = useRef(null);
  const [load, setLoad] = useState(true)


  // ------- state end -------
  // ------- useEffect start -------
  // Calling vendor profile
  useEffect(() => {
    vendorProfile();
    // viewProxyAddress()
    vendorSubscriptionDetails();
    //vendorSubscriptionHistory();
    listPaymentCard();
    businessInfo()
    bankInfo()
    vendorPromotionalCities()
    //subscriptionInfo()
  }, []);

  useEffect(() => {
    if (currentTab === "subscription") {
      vendorSubscriptionDetails();
      vendorSubscriptionHistory();
      subscriptionInfo()
    }
    if (currentTab === "proxy") {
      viewProxyAddress()
      subscriptionInfo()
    }
  }, [currentTab]);

  useEffect(() => {
    vendorProfile();
    if (location.state?.current_tab) {
      setCurrentTab(location.state.current_tab)
    }
  }, [vendorCancelSubscriptionResponse]);

  useEffect(() => {
    if (upgradePlanClicked) {
      setShowSubscriptionHistory(false)
    } else {
      setShowSubscriptionHistory(false)
      //setUpgradePlanClicked(true)
    }
  }, [upgradePlanClicked]);


  const upgradeClick = () => {
    setShowSubscriptionHistory(false)
    setUpgradePlanClicked(true)
  }

  // Vendor profile response
  useEffect(() => {
    if (vendorProfileResponse) {
      if (
        vendorProfileResponse.apiResponse &&
        vendorProfileResponse.apiResponse.basic_info
      ) {
        setBasicInfoData(vendorProfileResponse.apiResponse.basic_info);
        phoneFormat(
          vendorProfileResponse.apiResponse.basic_info?.phone_number,
          1
        );
        setVendorId(vendorProfileResponse.apiResponse.basic_info);
        if (vendorProfileResponse.apiResponse.basic_info?.is_identity_verified !== constants.STATUS_1) {
          if (bankInfoData.acc_number !== null && bankInfoData.acc_number !== '') {
            stripeVerify();
          }

        } else {
          setStripeVerified(true)
          setIsVerified("")
        }
      }
      if (vendorProfileResponse.apiResponse &&
        vendorProfileResponse.apiResponse.verification_info &&
        vendorProfileResponse.apiResponse.verification_info?.subscribed === constants.STATUS_FALSE) {
        setSubscribed(true)
        if (
          vendorProfileResponse.apiResponse.verification_info?.subscribed === constants.STATUS_FALSE &&
          vendorProfileResponse.apiResponse.verification_info?.is_first_subscription === constants.STATUS_FALSE &&
          currentTab !== "payment_method"
        ) {
          setUpgradePlanClicked(true);
          setCurrentTab("subscription");
        }

      } else {
        setSubscribed(false)
      }
      if (vendorProfileResponse.apiResponse &&
        vendorProfileResponse.apiResponse.verification_info &&
        vendorProfileResponse.apiResponse.verification_info?.is_first_subscription === constants.STATUS_TRUE) {
        setFirstSubscribed(true)
      } else {
        setFirstSubscribed(false)
      }
      setLoad(false)
    }
  }, [vendorProfileResponse]);

  // handling business info Response
  useEffect(() => {
    if (
      businessInfoResponse &&
      businessInfoResponse.apiStatus &&
      businessInfoResponse.apiErrorCode === constants.STATUS_200
    ) {
      if (
        businessInfoResponse.apiResponse &&
        businessInfoResponse.apiResponse.business_info
      ) {
        setBusinessInfoData(businessInfoResponse.apiResponse.business_info);
        setbusinessImage(
          businessInfoResponse.apiResponse &&
          businessInfoResponse.apiResponse.business_info.business_images
        );
      }
    }
  }, [businessInfoResponse]);

  // handling business info Response
  useEffect(() => {
    if (
      vendorPromotionalCitiesResponse &&
      vendorPromotionalCitiesResponse.apiStatus &&
      vendorPromotionalCitiesResponse.apiErrorCode === constants.STATUS_200
    ) {
      if (
        vendorPromotionalCitiesResponse.apiResponse &&
        vendorPromotionalCitiesResponse.apiResponse.length !== constants.STATUS_0
      ) {
        const promotional_city = vendorPromotionalCitiesResponse.apiResponse
        const city_array = []
        promotional_city.map((data) => {
          city_array.push(data.cityname)
        })
        setPromotionalCities(city_array)
      }
    }
  }, [vendorPromotionalCitiesResponse]);

  // handling bank info Response
  useEffect(() => {
    if (
      bankInfoResponse &&
      bankInfoResponse.apiStatus &&
      bankInfoResponse.apiErrorCode === constants.STATUS_200
    ) {
      if (
        bankInfoResponse.apiResponse &&
        bankInfoResponse.apiResponse.account_info
      ) {
        setBankInfoData(bankInfoResponse.apiResponse.account_info);
      }
    }
  }, [bankInfoResponse]);

  // handling subscription info Response
  useEffect(() => {
    if (
      subscriptionInfoResponse &&
      subscriptionInfoResponse.apiStatus &&
      subscriptionInfoResponse.apiErrorCode === constants.STATUS_200
    ) {
      if (
        subscriptionInfoResponse.apiResponse &&
        subscriptionInfoResponse.apiResponse.subscription_info
      ) {
        setSubscriptionInfoData(
          subscriptionInfoResponse.apiResponse.subscription_info
        );
        setCurrentPlan(subscriptionInfoResponse.apiResponse.subscription_info?.subscription_type)
        setZiplimit(subscriptionInfoResponse.apiResponse.subscription_info?.number_of_zipcodes)
      }
      if (
        subscriptionInfoResponse.apiResponse &&
        subscriptionInfoResponse.apiResponse.subscription_list
      ) {
        setSubscriptionListData(
          subscriptionInfoResponse.apiResponse.subscription_list
        );
      }
    }
  }, [subscriptionInfoResponse]);

  // handling Edit Basic info Toast and response
  useEffect(() => {
    if (
      editBasicInfoResponse &&
      editBasicInfoResponse.apiStatus &&
      editBasicInfoResponse.apiErrorCode === constants.STATUS_200
    ) {
      toast.success(editBasicInfoResponse.apiMessage);
      resetResponse("vendorEditBasicInfo");
    } else if (
      editBasicInfoResponse &&
      editBasicInfoResponse.apiErrorCode !== 0
    ) {
      toast.error(editBasicInfoResponse.apiMessage);
      handleCancelBasicEdit();
      resetResponse("vendorEditBasicInfo");
    }
  }, [editBasicInfoResponse]);

  // handling Edit Basic info Toast and response
  useEffect(() => {
    if (
      addProxyAddressResponse &&
      addProxyAddressResponse.apiStatus &&
      addProxyAddressResponse.apiErrorCode === constants.STATUS_200
    ) {
      toast.success(addProxyAddressResponse.apiMessage);
      viewProxyAddress()
      resetResponse("addProxy");
    } else if (
      addProxyAddressResponse &&
      addProxyAddressResponse.apiErrorCode !== 0
    ) {
      toast.error(addProxyAddressResponse.apiMessage);
      handleCancelProxyEdit();
      resetResponse("addProxy");
    }
  }, [addProxyAddressResponse]);

  // handling remove subscription response
  useEffect(() => {
    if (
      removeSubscriptionApiResponse &&
      removeSubscriptionApiResponse.apiStatus &&
      removeSubscriptionApiResponse.apiErrorCode === constants.STATUS_200
    ) {
      editBusinessInfo({
        business_name: businessInfoData.business_name,
        business_number: businessInfoData.business_number,
        business_address: businessInfoData.business_address,
        business_address2: businessInfoData.business_address2,
        business_city: businessInfoData.business_city,
        business_state: businessInfoData.business_state,
        zip_code: businessInfoData.zip_code,
        business_country: businessInfoData.business_country,
        business_description: businessInfoData.business_description,
        about: businessInfoData.about,
        site_link: businessInfoData.site_link,
        review_url: businessInfoData.review_url,
        find_us_on: businessInfoData.find_us_on,
        longitude: businessInfoData.longitude,
        latitude: businessInfoData.latitude,
        sellerpolicy_link: businessInfoData.business_sellerpolicy_link,
        sellerpolicy_text: businessInfoData.business_sellerpolicy_text,
        is_sellerpolicy: businessInfoData.business_is_sellerpolicy,
      });
      setBusinessInfoError(intitalFormErrorBusinessInfo);
      setEditableBusiness(false);
      resetResponse("removeSubscription");
      vendorProfile();
      vendorSubscriptionDetails();
      vendorSubscriptionHistory();
      subscriptionInfo()
    } else if (
      removeSubscriptionApiResponse &&
      removeSubscriptionApiResponse.apiErrorCode !== 0
    ) {
      toast.error(removeSubscriptionApiResponse.apiMessage);
      handelCancelBusinessEdit();
      resetResponse("removeSubscription");
    }
  }, [removeSubscriptionApiResponse]);

  // handling list proxy
  useEffect(() => {
    if (
      viewProxyAddressResponse &&
      viewProxyAddressResponse.apiStatus &&
      viewProxyAddressResponse.apiErrorCode === constants.STATUS_200
    ) {
      if (viewProxyAddressResponse?.apiResponse && viewProxyAddressResponse.apiResponse?.proxy_info && viewProxyAddressResponse.apiResponse?.proxy_info?.length !== 0) {
        setProxyInfoData({
          address: viewProxyAddressResponse.apiResponse.proxy_info?.[0].address === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.address,
          city: viewProxyAddressResponse.apiResponse.proxy_info?.[0].city === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.city,
          country_code: viewProxyAddressResponse.apiResponse.proxy_info?.[0].country_code === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.country_code,
          country_id: viewProxyAddressResponse.apiResponse.proxy_info?.[0].country_id === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.country_id,
          country_name: viewProxyAddressResponse.apiResponse.proxy_info?.[0].country_name === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.country_name,
          state: viewProxyAddressResponse.apiResponse.proxy_info?.[0].state === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.state,
          zip: viewProxyAddressResponse.apiResponse.proxy_info?.[0].zip === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.zip,
          lat: viewProxyAddressResponse.apiResponse.proxy_info?.[0].lattitude === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.lattitude,
          long: viewProxyAddressResponse.apiResponse.proxy_info?.[0].longitude === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.longitude,

        })
        setProxyInfoDataResposne({
          address: viewProxyAddressResponse.apiResponse.proxy_info?.[0].address === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.address,
          city: viewProxyAddressResponse.apiResponse.proxy_info?.[0].city === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.city,
          country_code: viewProxyAddressResponse.apiResponse.proxy_info?.[0].country_code === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.country_code,
          country_id: viewProxyAddressResponse.apiResponse.proxy_info?.[0].country_id === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.country_id,
          country_name: viewProxyAddressResponse.apiResponse.proxy_info?.[0].country_name === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.country_name,
          state: viewProxyAddressResponse.apiResponse.proxy_info?.[0].state === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.state,
          zip: viewProxyAddressResponse.apiResponse.proxy_info?.[0].zip === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.zip,
          lat: viewProxyAddressResponse.apiResponse.proxy_info?.[0].lattitude === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.lattitude,
          long: viewProxyAddressResponse.apiResponse.proxy_info?.[0].longitude === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.longitude,

        })
        setProxyType(2)
      } else {
        setProxyType(1)
      }
      if (viewProxyAddressResponse.apiResponse && viewProxyAddressResponse.apiResponse.zip_codes) {
        if (currentPlan === SubscriptionPlans.Ultimate || currentPlan === SubscriptionPlans.Monthly) {
          setZipcodeLimit(5)
        }
        if (currentPlan ===  SubscriptionPlans.Advanced) {
          setZipcodeLimit(2)
        }
        if (currentPlan ===  SubscriptionPlans.Basic) {
          setZipcodeLimit(1)
        }
        let array = []
        if (viewProxyAddressResponse.apiResponse.zip_codes.length === 0) {
          setZipcodes([""])
        } else {
          viewProxyAddressResponse.apiResponse.zip_codes.map((data, index) => {
            array.push(data.zip_code)
          })
          setZipcodes(array)
          setZipCodeDataResponse(array)
        }
      }
    }
  }, [viewProxyAddressResponse]);

  // handling Edit Bank info Toast and response
  useEffect(() => {
    if (
      editBankInfoResponse &&
      editBankInfoResponse.apiStatus &&
      editBankInfoResponse.apiErrorCode === constants.STATUS_200
    ) {
      if (
        editBankInfoResponse.apiResponse &&
        editBankInfoResponse.apiResponse.accntDetails
      ) {
        if (
          editBankInfoResponse.apiResponse.accntDetails.verifiedStatus == "new"
        ) {
          setStripeLoading(false)
          if (bankInfoData.acc_number !== null && bankInfoData.acc_number !== '') {
            stripeVerify();
          }
        }
      }
      if (editBankInfoResponse.apiResponse.bank_info) {
        setStripeLoading(false)
        setBankInfoData({
          acc_type: editBankInfoResponse.apiResponse.bank_info?.acc_type,
          acc_name: editBankInfoResponse.apiResponse.bank_info?.acc_name,
          routing_no: editBankInfoResponse.apiResponse.bank_info?.routing_no,
          acc_number: editBankInfoResponse.apiResponse.bank_info?.acc_number,
        });
      }
      vendorProfile();
      resetResponse("vendorEditBankInfo");
    } else if (
      editBankInfoResponse &&
      editBankInfoResponse.apiErrorCode !== 0
    ) {
      setStripeLoading(false)
      setShowStripe(false)
      toast.error(editBankInfoResponse.apiMessage);
      setBankData(intitalValuesBankInfo)
      handleCancelBankEdit();
      resetResponse("vendorEditBankInfo");
    }
    bankInfo();
  }, [editBankInfoResponse]);

  // handling Edit Business info Toast and response
  useEffect(() => {
    if (
      editBusinessInfoResponse.apiStatus &&
      editBusinessInfoResponse.apiErrorCode === constants.STATUS_200
    ) {
      businessInfo()
      toast.success(editBusinessInfoResponse.apiMessage);
      resetResponse("vendorEditBusinessInfo");
    } else if (editBusinessInfoResponse.apiErrorCode !== 0) {
      toast.error(editBusinessInfoResponse.apiMessage);
      handelCancelBusinessEdit();
      resetResponse("vendorEditBusinessInfo");
    }
  }, [editBusinessInfoResponse]);

  // handling stripe verify info Toast and response
  useEffect(() => {
    if (
      stripeVerifyResponse.apiStatus &&
      stripeVerifyResponse.apiErrorCode === constants.STATUS_200
    ) {
      if (
        stripeVerifyResponse.apiResponse &&
        stripeVerifyResponse.apiResponse.accountLinks
      ) {
        setStripeVerified(false)
        setIsVerified(stripeVerifyResponse.apiResponse.accountLinks?.url);
        setVerifiedMessage("");
      }
      if (
        stripeVerifyResponse.apiResponse &&
        stripeVerifyResponse.apiResponse.identityVerified
      ) {
        setStripeVerified(true)
        setIsVerified("");
        setVerifiedMessage(stripeVerifyResponse.apiMessage);
      }
      resetResponse("stripeVerify");
    } else if (stripeVerifyResponse.apiErrorCode !== 0) {
      if (stripeVerifyResponse.apiResponse)
        toast.error(stripeVerifyResponse.apiMessage);
      resetResponse("stripeVerify");
    }
  }, [stripeVerifyResponse]);

  // Add Subscription Response
  useEffect(() => {
    if (addSubscriptionApiResponse && addSubscriptionApiResponse.apiStatus) {
      if (addSubscriptionApiResponse.apiErrorCode == constants.STATUS_200) {
        vendorProfile();
        vendorSubscriptionDetails();
        vendorSubscriptionHistory();
        toast.success(addSubscriptionApiResponse.apiMessage);
      } else {
        toast.error(addSubscriptionApiResponse.apiMessage);
      }
      resetResponse("addSubscription");
    }
  }, [addSubscriptionApiResponse]);

  useEffect(() => {
    if (
      imagePreSignedUploadResponse &&
      imagePreSignedUploadResponse.apiStatus
    ) {
      if (imagePreSignedUploadResponse.apiErrorCode == constants.STATUS_200) {
        setbusinessImage(imagePreSignedUploadResponse.apiResponse);
      } else {
        toast.error(imagePreSignedUploadResponse.apiMessage);
      }
      resetResponse("vendorImagePreSigned");
    }
    setUploadPic(false);
  }, [imagePreSignedUploadResponse]);

  // removeProductResponse
  useEffect(() => {
    if (removeBusinessImageResponse && removeBusinessImageResponse.apiStatus) {
      if (removeBusinessImageResponse.apiErrorCode === constants.STATUS_200) {
        toast.success(removeBusinessImageResponse.apiMessage);
      } else {
        toast.error(removeBusinessImageResponse.apiMessage);
        vendorProfile();
      }
      resetResponse("removeBusinessImage");
    }
  }, [removeBusinessImageResponse]);

  // handle phone formatting
  useEffect(() => {
    phoneFormat(businessInfoData.business_number, 2);
  }, [businessInfoData.business_number]);

  // useEffect(() => {
  //   if (!loadMap && editableBusiness) {
  //     loadGoogleMapScript(() => {
  //       setLoadMap(true);
  //     });
  //   }
  // }, [loadMap, editableBusiness]);


  useEffect(() => {
    if (place) {
      let address = place;
      let streetNumber = "";
      let routeInfo = "";
      let plusCode = "";
      let neighborhood = "";
      let business_city = "";
      let sublocalityLevel = "";
      let administrativeAreaLevel_2 = "";
      let premise = "";
      address.forEach((item) => {

        if (item.types.indexOf("street_number") >= 0) {
          streetNumber = item.long_name + " ";
          setBusinessInfoData((businessInfoData) => ({
            ...businessInfoData,
            business_address: streetNumber,
          }));
        }

        if (item.types.indexOf("route") >= 0) {
          routeInfo = streetNumber + item.long_name + " ";
          setBusinessInfoData((businessInfoData) => ({
            ...businessInfoData,
            business_address: routeInfo,
          }));
        }
        if (item.types.indexOf("plus_code") >= 0) {
          if (streetNumber === "") {
            plusCode = streetNumber + routeInfo + item.long_name + " ";
          } else {
            plusCode = routeInfo + item.long_name + " ";
          }
          setBusinessInfoData((businessInfoData) => ({
            ...businessInfoData,
            business_address: plusCode,
          }));
        }

        if (item.types.indexOf("neighborhood") >= 0) {
          if (streetNumber === "") {
            neighborhood = streetNumber + routeInfo + plusCode + item.long_name + " ";
          } else {
            neighborhood = routeInfo + plusCode + item.long_name + " ";
          }
          setBusinessInfoData((businessInfoData) => ({
            ...businessInfoData,
            business_address: neighborhood,
          }));
        }
        if (item.types.indexOf("premise") >= 0) {
          if (streetNumber === "") {
            premise =
              streetNumber +
              routeInfo +
              plusCode +
              neighborhood +
              item.long_name +
              " ";
          } else {
            premise =
              routeInfo +
              plusCode +
              neighborhood +
              item.long_name +
              " ";
          }
          setBusinessInfoData((businessInfoData) => ({
            ...businessInfoData,
            business_address: premise,
          }));
        }
        if (item.types.indexOf("administrative_area_level_1") >= 0) {
          setBusinessInfoData((businessInfoData) => ({
            ...businessInfoData,
            business_state: item.short_name,
          }));
        }
        if (
          item.types.indexOf("locality") >= 0
        ) {
          business_city = item.long_name
          setBusinessInfoData((businessInfoData) => ({
            ...businessInfoData,
            business_city: item.long_name,
          }));
        } else if (item.types.indexOf("administrative_area_level_3") >= 0 && business_city === '') {
          setBusinessInfoData((businessInfoData) => ({
            ...businessInfoData,
            business_city: item.long_name,
          }));
        }

        if (item.types.indexOf("country") >= 0) {
          setBusinessInfoData((businessInfoData) => ({
            ...businessInfoData,
            business_country: item.short_name,
          }));
        }
        if (item.types.indexOf("postal_code") >= 0) {
          setBusinessInfoData((businessInfoData) => ({
            ...businessInfoData,
            zip_code: item.long_name,
          }));
        }
      });
    }
  }, [place]);

  //proxy place set
  useEffect(() => {
    if (placeProxy) {
      let address = placeProxy;
      let streetNumber = "";
      let routeInfo = "";
      let plusCode = "";
      let neighborhood = "";
      let premise = "";
      let business_city = "";
      address.forEach((item) => {

        if (item.types.indexOf("street_number") >= 0) {
          streetNumber = item.long_name + " ";
          setProxyInfoData((proxyInfoData) => ({
            ...proxyInfoData,
            address: streetNumber,
          }));
        }

        if (item.types.indexOf("route") >= 0) {
          routeInfo = streetNumber + item.long_name + " ";
          setProxyInfoData((proxyInfoData) => ({
            ...proxyInfoData,
            address: routeInfo,
          }));
        }
        if (item.types.indexOf("plus_code") >= 0) {
          if (streetNumber === "") {
            plusCode = streetNumber + routeInfo + item.long_name + " ";
          } else {
            plusCode = routeInfo + item.long_name + " ";
          }
          setProxyInfoData((proxyInfoData) => ({
            ...proxyInfoData,
            address: plusCode,
          }));
        }

        if (item.types.indexOf("neighborhood") >= 0) {
          if (streetNumber === "") {
            neighborhood = streetNumber + routeInfo + plusCode + item.long_name + " ";
          } else {
            neighborhood = routeInfo + plusCode + item.long_name + " ";
          }
          setProxyInfoData((proxyInfoData) => ({
            ...proxyInfoData,
            address: neighborhood,
          }));
        }
        if (item.types.indexOf("premise") >= 0) {
          if (streetNumber === "") {
            premise =
              streetNumber +
              routeInfo +
              plusCode +
              neighborhood +
              item.long_name +
              " ";
          } else {
            premise =
              routeInfo +
              plusCode +
              neighborhood +
              item.long_name +
              " ";
          }
          setProxyInfoData((proxyInfoData) => ({
            ...proxyInfoData,
            address: premise,
          }));
        }
        if (item.types.indexOf("administrative_area_level_1") >= 0) {
          setProxyInfoData((proxyInfoData) => ({
            ...proxyInfoData,
            state: item.short_name,
          }));
        }
        if (
          item.types.indexOf("locality") >= 0
        ) {
          business_city = item.long_name
          setProxyInfoData((proxyInfoData) => ({
            ...proxyInfoData,
            city: item.long_name,
          }));
        } else if (item.types.indexOf("administrative_area_level_3") >= 0 && business_city === '') {
          setProxyInfoData((proxyInfoData) => ({
            ...proxyInfoData,
            city: item.long_name,
          }));
        }

        if (item.types.indexOf("country") >= 0) {
          setProxyInfoData((proxyInfoData) => ({
            ...proxyInfoData,
            country_name: item.short_name,
          }));
        }
        if (item.types.indexOf("postal_code") >= 0) {
          setProxyInfoData((proxyInfoData) => ({
            ...proxyInfoData,
            zip: item.long_name,
          }));
          handleGetLocation(item.long_name)
        }
      });
    }
  }, [placeProxy]);

  useEffect(() => {
    fetch("https://geolocation-db.com/json/")
      .then((response) => response.json())
      .then((data) => {
        setIpAddress(data?.IPv4);
      });
  }, []);

  //handle latitude and longitude
  useEffect(() => {
    if (lat && long) {
      setBusinessInfoData((businessInfoData) => ({
        ...businessInfoData,
        latitude: lat,
        longitude: long,
      }));
    }
  }, [lat, long]);

  useEffect(() => {
    if (createSubscriptionResponse && createSubscriptionResponse.apiStatus)
      if (createSubscriptionResponse.apiErrorCode === constants.STATUS_200) {
        if (
          createSubscriptionResponse.apiResponse &&
          createSubscriptionResponse.apiResponse.paymentStatus === false &&
          createSubscriptionResponse.apiResponse.paymentInfo
        ) {
          payment(createSubscriptionResponse)
          resetResponse("createSubscription");
        } else if (createSubscriptionResponse.apiResponse &&
          createSubscriptionResponse.apiResponse.paymentStatus === true) {
          setTimeout(() => {
            subscriptionPaymentSucess()
            toast.success(
              createSubscriptionResponse.apiMessage
            );
            resetResponse("createSubscription");
          }, 1500);
        }
      } else if (createSubscriptionResponse.apiErrorCode === constants.STATUS_400) {
        toast.error(createSubscriptionResponse.apiMessage);
        resetResponse("createSubscription");
        setLoadingModal(false)
      }
    setLoadingModal(false)
  }, [createSubscriptionResponse]);

  useEffect(() => {
    if (upgradeSubscriptionResponse && upgradeSubscriptionResponse.apiStatus)
      if (upgradeSubscriptionResponse.apiErrorCode === constants.STATUS_200) {
        if (
          upgradeSubscriptionResponse.apiResponse &&
          upgradeSubscriptionResponse.apiResponse.paymentStatus === false &&
          upgradeSubscriptionResponse.apiResponse.paymentInfo
        ) {
          payment(upgradeSubscriptionResponse)
          resetResponse("subscriptionUpgrade");
        } else if (upgradeSubscriptionResponse.apiResponse &&
          upgradeSubscriptionResponse.apiResponse.paymentStatus === true) {
          setTimeout(() => {
            subscriptionPaymentSucess()
            toast.success(
              upgradeSubscriptionResponse.apiMessage
            );
            resetResponse("subscriptionUpgrade");
          }, 1500);

        }
      } else if (upgradeSubscriptionResponse.apiErrorCode === constants.STATUS_400) {
        toast.error(upgradeSubscriptionResponse.apiMessage);
        resetResponse("subscriptionUpgrade");
        setLoadingModal(false)
      }
    setLoadingModal(false)
  }, [upgradeSubscriptionResponse]);

  useEffect(() => {
    if (downgradeSubscriptionResponse && downgradeSubscriptionResponse.apiStatus)
      if (downgradeSubscriptionResponse.apiErrorCode === constants.STATUS_200) {
        if (
          downgradeSubscriptionResponse.apiResponse &&
          downgradeSubscriptionResponse.apiResponse.paymentStatus === false &&
          downgradeSubscriptionResponse.apiResponse.paymentInfo
        ) {
          payment(downgradeSubscriptionResponse)
          resetResponse("downgradeSubscription");
        } else if (downgradeSubscriptionResponse.apiResponse &&
          downgradeSubscriptionResponse.apiResponse.paymentStatus === true) {
          setTimeout(() => {
            subscriptionPaymentSucess()
            toast.success(
              downgradeSubscriptionResponse.apiMessage
            );
            resetResponse("downgradeSubscription");
          }, 1500);

        }
      } else if (downgradeSubscriptionResponse.apiErrorCode === constants.STATUS_400) {
        toast.error(downgradeSubscriptionResponse.apiMessage);
        resetResponse("downgradeSubscription");
        setLoadingModal(false)
      }
    setLoadingModal(false)
  }, [downgradeSubscriptionResponse]);

  useEffect(() => {
    if (expireSubscriptionResponse && expireSubscriptionResponse.apiStatus)
      if (expireSubscriptionResponse.apiErrorCode === constants.STATUS_200) {
        if (
          expireSubscriptionResponse.apiResponse &&
          expireSubscriptionResponse.apiResponse.paymentStatus === false &&
          expireSubscriptionResponse.apiResponse.paymentInfo
        ) {
          payment(expireSubscriptionResponse)
          resetResponse("expireSubscription");
        } else if (expireSubscriptionResponse.apiResponse &&
          expireSubscriptionResponse.apiResponse.paymentStatus === true) {
          setTimeout(() => {
            subscriptionPaymentSucess()
            toast.success(
              expireSubscriptionResponse.apiMessage
            );
            resetResponse("expireSubscription");
          }, 1500);

        }
      } else if (expireSubscriptionResponse.apiErrorCode === constants.STATUS_400) {
        toast.error(expireSubscriptionResponse.apiMessage);
        resetResponse("expireSubscription");
        setLoadingModal(false)
      }
    setLoadingModal(false)
  }, [expireSubscriptionResponse]);

  useEffect(() => {
    if (bankInfoData.acc_type !== "" && bankInfoData.acc_type === constants.STATUS_1) {
      personalAccType();
    } else if (bankInfoData.acc_type !== "" && bankInfoData.acc_type === constants.STATUS_2) {
      businessAccType();
    }
  }, [bankInfoData]);

  useEffect(() => {
    if (editableBank) {
      if (accountType == false) {
        personalAccType();
      } else {
        businessAccType();
      }
    }
  }, [editableBank]);

  useEffect(() => {
    if (accountType === false) {
      onChangeMaskAccPersonal(bankData.acc_number, "accPersonal");
    } else {
      onChangeMaskAccBusiness(bankData.acc_number, "accBusiness");
    }
  }, [bankData.acc_number]);

  useEffect(() => {
    if (deleteBankinfoClicked) {
      deleteBankInfo()
    }
  }, [deleteBankinfoClicked]);

  useEffect(() => {
    if (accountType === false) {
      onChangeMaskAccPersonal(bankData.re_acc_number);
    } else {
      onChangeMaskAccBusiness(bankData.re_acc_number);
    }
  }, [bankData.re_acc_number]);

  useEffect(() => {
    if (accountType == true) {
      onChangeMaskrouting(bankData.routing_no, "business")
    } else {
      onChangeMaskrouting(bankData.routing_no, 'personal')
    }
  }, [bankData.routing_no])


  useEffect(() => {
    if (cancelSubscriptionClicked) {
      cancelSubscription();
    }
  }, [cancelSubscriptionClicked]);

  //  Subscription details Response
  useEffect(() => {
    if (vendorSubscriptionDetailsResponse && vendorSubscriptionDetailsResponse.apiStatus) {
      if (vendorSubscriptionDetailsResponse.apiErrorCode === constants.STATUS_200) {
        if (vendorSubscriptionDetailsResponse.apiResponse && vendorSubscriptionDetailsResponse.apiResponse[0])
          setSubscriptionDetails(vendorSubscriptionDetailsResponse.apiResponse[0])
      } else if (vendorSubscriptionDetailsResponse.apiErrorCode === constants.STATUS_400) {
        setSubscriptionDetails('')
      }
      resetResponse("subscriptionDetails");
    }
  }, [vendorSubscriptionDetailsResponse]);

  //delete bank info
  useEffect(() => {
    if (deleteBankinfoResponse && deleteBankinfoResponse.apiStatus) {
      if (deleteBankinfoResponse.apiErrorCode === constants.STATUS_200) {
        setDeleteBankinfoClicked(false)
        setDeleteBankinfoPopUp(false)
        toast.success(
          deleteBankinfoResponse.apiMessage
        );
        setStripeVerified('')
        setBankData(intitalValuesBankInfo)
        setPersonalAccount(intitalValuesBankInfo)
        setBusinessAccount(intitalValuesBankInfo)
        setCurrentTab('bankinfo')
        setEditableBank(false);
        bankInfo()
        resetResponse("deleteBankinfo");
      }
    }
  }, [deleteBankinfoResponse]);

  //  Subscription History  Response
  useEffect(() => {
    if (vendorSubscriptionHistoryResponse && vendorSubscriptionHistoryResponse.apiStatus) {
      if (vendorSubscriptionHistoryResponse.apiErrorCode == constants.STATUS_200) {
        if (vendorSubscriptionHistoryResponse.apiResponse && vendorSubscriptionHistoryResponse.apiResponse.subscription_history)
          setSubscriptionHistory(vendorSubscriptionHistoryResponse.apiResponse.subscription_history)
      } else if (vendorSubscriptionHistoryResponse.apiErrorCode == constants.STATUS_400) {
        setSubscriptionHistory([])
      }
      resetResponse("subscriptionHistory");
    }
  }, [vendorSubscriptionHistoryResponse]);

  //  Subscription Cancel  Response
  useEffect(() => {
    if (vendorCancelSubscriptionResponse && vendorCancelSubscriptionResponse.apiStatus) {
      if (vendorCancelSubscriptionResponse.apiErrorCode == constants.STATUS_200) {
        cancelSubscriptionClose();
        subscriptionInfo()
        vendorSubscriptionDetails();
        vendorSubscriptionHistory();
      }
      resetResponse("cancelSubscription");
    }
  }, [vendorCancelSubscriptionResponse]);

  useEffect(() => {
    if (savePaymentCardResponse && savePaymentCardResponse.apiStatus) {
      if (savePaymentCardResponse.apiErrorCode == constants.STATUS_200) {
        toast.success(savePaymentCardResponse.apiMessage);
        listPaymentCard();
      } else {
        toast.error(savePaymentCardResponse.apiMessage);
        setCardSave(false);
      }
      resetResponse("savePaymentCard");
    }
  }, [savePaymentCardResponse]);

  useEffect(() => {
    if (listPaymentCardResponse && listPaymentCardResponse.apiStatus) {
      if (
        listPaymentCardResponse.apiErrorCode == constants.STATUS_200 &&
        listPaymentCardResponse.apiResponse.cardInfo
      ) {
        setCardDetails(listPaymentCardResponse.apiResponse.cardInfo);
      } else if (listPaymentCardResponse.apiErrorCode === constants.STATUS_400) {
        setCardDetails("")
      }
      resetResponse("listPaymentCard");
      setCardSave(false)
    }
  }, [listPaymentCardResponse]);

  useEffect(() => {
    if (deletePaymentCardResponse && deletePaymentCardResponse.apiStatus) {
      if (deletePaymentCardResponse.apiErrorCode == constants.STATUS_200) {
        toast.success(deletePaymentCardResponse.apiMessage);
        listPaymentCard();
      } else {
        toast.error(deletePaymentCardResponse.apiMessage);
      }
      resetResponse("deletePaymentCard");
    }
  }, [deletePaymentCardResponse]);

  useEffect(() => {
    if (vendorBankDetailsResponse && vendorBankDetailsResponse.apiStatus) {
      if (vendorBankDetailsResponse.apiErrorCode == constants.STATUS_200) {
        if (vendorBankDetailsResponse.apiResponse) {
          setBankInfoData(vendorBankDetailsResponse.apiResponse)
        }
      } else {
        toast.error(vendorBankDetailsResponse.apiMessage);
      }
      resetResponse("bankDetails");
    }
  }, [vendorBankDetailsResponse]);

  // ------- useEffect End -------

  //-------- Functions -----------

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //handle image delete modal
  const handleCloseDelete = () => {
    setShowDelete(false)
    setImageDeleteId('')
  }
  const handleShowDelete = (e) => {
    setShowDelete(true)
    setImageDeleteId(e)
  }

  // handle image delete 
  const handleDeleteButton = () => {
    deleteImage(imageDeleteId)
    handleCloseDelete()
  }
  const payment = (api) => {
    navigate("/payment", {
      state: {
        client_secret:
          api.apiResponse.paymentInfo
            ?.clientSecret,
        subscriptionId:
          api.apiResponse.paymentInfo
            ?.subscriptionId,
        amount: planData.amount,
        plan_id: planData.plan_id,
        is_upgrade: planData.is_upgrade,
        lookupKey:planData.lookupKey
      },
    });
    setLoadingModal(false)
  }

  const subscriptionPaymentSucess = () => {
    setLoadingModal(false)
    vendorProfile();
    subscriptionInfo()
    vendorSubscriptionDetails();
    vendorSubscriptionHistory();
    setUpgradePlanClicked(false);
  }

  const cancelSubscriptionClose = () => setShowcancelSubscription(false);

  // handling Cancel Basic info input
  const handleCancelBasicEdit = () => {
    setEditable(false);
    if (vendorProfileResponse) {
      if (
        vendorProfileResponse.apiResponse &&
        vendorProfileResponse.apiResponse.basic_info
      ) {
        setBasicInfoData(vendorProfileResponse.apiResponse.basic_info);
      }
    }
  };

  // handling Cancel Bank info input
  const handleCancelBankEdit = () => {
    setEditableBank(false);
    if (bankInfoResponse) {
      if (
        bankInfoResponse.apiResponse &&
        bankInfoResponse.apiResponse.account_info
      ) {
        setBankInfoData(bankInfoResponse.apiResponse.account_info);
        if (accountType == false) {
          personalAccType();
        } else {
          businessAccType();
        }
      }
    }
  };

  // handling Cancel Business info input
  const handelCancelBusinessEdit = () => {
    setEditableBusiness(false);
    setBusinessInfoError(intitalFormErrorBusinessInfo);
    if (businessInfoResponse) {
      if (
        businessInfoResponse.apiResponse &&
        businessInfoResponse.apiResponse.business_info
      ) {
        setBusinessInfoData(businessInfoResponse.apiResponse.business_info);
        setbusinessImage(
          businessInfoResponse.apiResponse &&
          businessInfoResponse.apiResponse.business_info.business_images
        );
      }
    }
  };

  // handling Cancel Proxy input
  const handleCancelProxyEdit = () => {
    setEditableProxy(false);
    setProxyInfoError(intitalValuesProxyAddressError)
    setZipcodesError([""])
    setProxyInfoData(intitalValuesProxyAddress)
    if (viewProxyAddressResponse && viewProxyAddressResponse.apiResponse.proxy_info) {
      if (viewProxyAddressResponse.apiResponse.proxy_info.length !== 0) {
        setProxyInfoData({
          address: viewProxyAddressResponse.apiResponse.proxy_info?.[0].address === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.address,
          city: viewProxyAddressResponse.apiResponse.proxy_info?.[0].city === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.city,
          country_code: viewProxyAddressResponse.apiResponse.proxy_info?.[0].country_code === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.country_code,
          country_id: viewProxyAddressResponse.apiResponse.proxy_info?.[0].country_id === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.country_id,
          country_name: viewProxyAddressResponse.apiResponse.proxy_info?.[0].country_name === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.country_name,
          state: viewProxyAddressResponse.apiResponse.proxy_info?.[0].state === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.state,
          zip: viewProxyAddressResponse.apiResponse.proxy_info?.[0].zip === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.zip,
          lat: viewProxyAddressResponse.apiResponse.proxy_info?.[0].lattitude === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.lattitude,
          long: viewProxyAddressResponse.apiResponse.proxy_info?.[0].longitude === null ? "" : viewProxyAddressResponse.apiResponse.proxy_info?.[0]?.longitude,
        })
        setProxyType(2)
      } else {
        setProxyType(1)
      }
      if (viewProxyAddressResponse.apiResponse && viewProxyAddressResponse.apiResponse.zip_codes) {
        let array = []
        if (viewProxyAddressResponse.apiResponse.zip_codes.length === 0) {
          setZipcodes([""])
        } else {
          viewProxyAddressResponse.apiResponse.zip_codes.map((data, index) => {
            array.push(data.zip_code)
          })
          setZipcodes(array)
        }
      }
    } else {
      setProxyType(1)
      setZipcodes([""])
      setProxyInfoData(intitalValuesProxyAddress)
    }
  };

  // handling edit  Basic info
  const handleBasicInfoEdit = () => {
    setEditable(true);
    if (editableBank) {
      handleCancelBankEdit();
    }
    if (editableBusiness) {
      handelCancelBusinessEdit();
    }
    if (editableProxy) {
      handleCancelProxyEdit()
    }
  };

  // handling edit  Business info
  const handleBusinessInfoEdit = () => {
    setEditableBusiness(!editableBusiness);
    if (editable) {
      handleCancelBasicEdit();
    }
    if (editableBank) {
      handleCancelBankEdit();
    }
    if (editableProxy) {
      handleCancelProxyEdit()
    }
  };

  // handling edit  Bank info
  const handleBankInfoEdit = () => {
    setEditableBank(!editableBank);
    if (editable) {
      handleCancelBasicEdit();
    }
    if (editableBusiness) {
      handelCancelBusinessEdit();
    }
    if (editableProxy) {
      handleCancelProxyEdit()
    }
  };

  // handling edit  Bank info
  const handleProxyEdit = () => {
    setEditableProxy(!editableProxy)
    setProxyZipLimit(false)
    if (editable) {
      handleCancelBasicEdit();
    }
    if (editableBusiness) {
      handelCancelBusinessEdit();
    }
    if (editableBank) {
      handleCancelBankEdit();
    }
  };

  // handling validation for Basic info
  const basicInfoValidation = () => {
    let isValid = true;
    let firstNameErrMsg = nameValidation(basicInfoData.f_name);
    let lastNameErrMsg = nameValidation(basicInfoData.l_name);
    if (basicInfoData.f_name.trim() === "") {
      isValid = false;
      handleClose();
      setBasicInfoError((basicInfoError) => ({
        ...basicInfoError,
        ["f_nameError"]: alerts.ENTER_FIRST_NAME,
      }));
    }
    else if (firstNameErrMsg !== "") {
      isValid = false;
      setBasicInfoError(basicInfoError => ({ ...basicInfoError, ["f_nameError"]: firstNameErrMsg }))
    }
    if (basicInfoData.l_name.trim() === "") {
      isValid = false;
      handleClose();
      setBasicInfoError((basicInfoError) => ({
        ...basicInfoError,
        ["l_nameError"]: alerts.ENTER_LAST_NAME,
      }));
    }
    else if (firstNameErrMsg !== "") {
      isValid = false;
      setBasicInfoError(basicInfoError => ({ ...basicInfoError, ["l_nameError"]: lastNameErrMsg }))
    }

    return isValid;
  };

  // handling validation for Bank info
  const bankInfoValidation = (accType) => {
    setBankInfoError("")
    let isValid = true;
    if (accType.acc_name.trim() === "") {
      isValid = false;
      handleClose();
      setBankInfoError((bankInfoError) => ({
        ...bankInfoError,
        ["acc_nameError"]: alerts.ENTER_ACCOUNT_NAME,
      }));
    }
    if (accType.acc_number.trim() === "") {
      isValid = false;
      handleClose();
      setBankInfoError((bankInfoError) => ({
        ...bankInfoError,
        ["acc_numberError"]: alerts.ENTER_ACCOUNT_NUMBER,
      }));
    }
    if (accType.routing_no.trim() === "") {
      isValid = false;
      handleClose();
      setBankInfoError((bankInfoError) => ({
        ...bankInfoError,
        ["routing_noError"]: alerts.ENTER_ROUTING_NUMBER,
      }));
    }

    if (accType.re_acc_number.trim() === "") {
      isValid = false;
      handleClose();
      setBankInfoError((bankInfoError) => ({
        ...bankInfoError,
        ["re_acc_numberError"]: alerts.ENTER_RE_ACCOUNT_NUMBER,
      }));
    } else if (accType.re_acc_number !== accType.acc_number) {
      isValid = false;
      handleClose();
      setBankInfoError((bankInfoError) => ({
        ...bankInfoError,
        ["re_acc_numberError"]: alerts.ENTER_ACCOUNT_DOSENT_MATCH,
      }));
    }
    return isValid;
  };

  // handling validation for Business info
  const businessInfoValidation = () => {
    let isValid = true;
    let checkSellerLink = checkHttpsLink(businessInfoData.business_sellerpolicy_link)
    let checkCustomerLink = checkHttpsLink(businessInfoData.review_url)
    let checkFindLink = checkHttpsLink(businessInfoData.find_us_on)
    let checkWebLink = checkHttpsLink(businessInfoData.site_link)
    let phoneNumberErrMsg =
      businessInfoData.business_number !== ""
        ? phoneNumberValidation(businessInfoData.business_number)
        : "";
    setBusinessInfoError(intitalFormErrorBusinessInfo);
    if (businessInfoData.business_name.trim() === "") {
      isValid = false;
      handleClose();
      setBusinessInfoError((businessInfoError) => ({
        ...businessInfoError,
        ["business_nameError"]: alerts.ENTER_BUSINESS_NAME,
      }));
    }

    if (phoneNumberErrMsg !== "") {
      isValid = false;
      handleClose();
      setBusinessInfoError((businessInfoError) => ({
        ...businessInfoError,
        ["business_numberError"]: phoneNumberErrMsg,
      }));
    }

    if (businessInfoData.business_address.trim() === "") {
      isValid = false;
      handleClose();
      setBusinessInfoError((businessInfoError) => ({
        ...businessInfoError,
        ["business_addressError"]: alerts.ENTER_ADDRESS,
      }));
    }
    if (businessInfoData.business_city.trim() === "") {
      isValid = false;
      handleClose();
      setBusinessInfoError((businessInfoError) => ({
        ...businessInfoError,
        ["business_cityError"]: alerts.ENTER_CITY,
      }));
    }

    if (businessInfoData.business_state.toString().trim() === "") {
      isValid = false;
      handleClose();
      setBusinessInfoError((businessInfoError) => ({
        ...businessInfoError,
        ["business_stateError"]: alerts.ENTER_STATE,
      }));
    }

    if (businessInfoData.business_country.toString().trim() === "") {
      isValid = false;
      handleClose();
      setBusinessInfoError((businessInfoError) => ({
        ...businessInfoError,
        ["business_countryError"]: alerts.ENTER_COUNTRY,
      }));
    }
    if (businessInfoData.latitude.toString().trim() === "") {
      isValid = false;
      handleClose();
      toast.error("Some error has occurred please try again.");
    }
    if (businessInfoData.longitude.toString().trim() === "") {
      isValid = false;
      handleClose();
      toast.error("Some error has occurred please try again.");
    }


    if ((businessInfoData.business_is_sellerpolicy === constants.STATUS_1) && (businessInfoData.business_sellerpolicy_link == '' || businessInfoData.business_sellerpolicy_link == null)) {
      isValid = false;
      handleClose();
      setBusinessInfoError((businessInfoError) => ({
        ...businessInfoError,
        ["site_linkError"]: alerts.ENTER_SELLERPOLICYLINK,
      }));
    }

    if ((businessInfoData.business_is_sellerpolicy === constants.STATUS_1) && businessInfoData.business_sellerpolicy_link !== '' && businessInfoData.business_sellerpolicy_link !== null && !checkSellerLink) {
      isValid = false;
      handleClose();
      setBusinessInfoError((businessInfoError) => ({
        ...businessInfoError,
        ["site_linkError"]: alerts.ENTER_VALID_LINK,
      }));
    }
    if (businessInfoData.review_url !== '' && businessInfoData.review_url !== null && !checkCustomerLink) {
      isValid = false;
      handleClose();
      setBusinessInfoError((businessInfoError) => ({
        ...businessInfoError,
        ["review_urlError"]: alerts.ENTER_VALID_LINK,
      }));
    }
    if (businessInfoData.find_us_on !== '' && businessInfoData.find_us_on !== null && !checkFindLink) {
      isValid = false;
      handleClose();
      setBusinessInfoError((businessInfoError) => ({
        ...businessInfoError,
        ["find_us_onError"]: alerts.ENTER_VALID_LINK,
      }));
    }
    if (businessInfoData.site_link !== '' && businessInfoData.site_link !== null && !checkWebLink) {
      isValid = false;
      handleClose();
      setBusinessInfoError((businessInfoError) => ({
        ...businessInfoError,
        ["web_LinkError"]: alerts.ENTER_VALID_LINK,
      }));
    }


    if ((businessInfoData.business_is_sellerpolicy === constants.STATUS_2) && (businessInfoData.business_sellerpolicy_text == '' || businessInfoData.business_sellerpolicy_text == null)) {
      isValid = false;
      handleClose();
      setBusinessInfoError((businessInfoError) => ({
        ...businessInfoError,
        ["site_textError"]: alerts.ENTER_SELLERPOLICYTEXT,
      }));
    }

    return isValid;
  };

  const validateproxy = () => {
    let isValid = true
    setProxyInfoError(intitalValuesProxyAddressError)
    if (currentPlan !== SubscriptionPlans.Basic && proxyInfoData.address.trim() === "" && (proxyInfoData.city.trim() !== "" || proxyInfoData.state.toString().trim() !== "" || proxyInfoData.zip.trim() !== "" || proxyInfoData.country_name.toString().trim() !== "")) {
      isValid = false;
      handleClose();
      setProxyInfoError((proxyInfoError) => ({
        ...proxyInfoError,
        ["addressError"]: alerts.ENTER_ADDRESS,
      }));
    }
    if (currentPlan !== SubscriptionPlans.Basic && proxyInfoData.city.trim() === "" && (proxyInfoData.address.trim() !== "" || proxyInfoData.state.toString().trim() !== "" || proxyInfoData.zip.trim() !== "" || proxyInfoData.country_name.toString().trim() !== "")) {
      isValid = false;
      handleClose();
      setProxyInfoError((proxyInfoError) => ({
        ...proxyInfoError,
        ["cityError"]: alerts.ENTER_CITY,
      }));
    }

    if (currentPlan !== SubscriptionPlans.Basic && proxyInfoData.state.toString().trim() === "" && (proxyInfoData.city.trim() !== "" || proxyInfoData.address.toString().trim() !== "" || proxyInfoData.zip.trim() !== "" || proxyInfoData.country_name.toString().trim() !== "")) {
      isValid = false;
      handleClose();
      setProxyInfoError((proxyInfoError) => ({
        ...proxyInfoError,
        ["stateError"]: alerts.ENTER_STATE,
      }));
    }

    if (currentPlan !== SubscriptionPlans.Basic && proxyInfoData.country_name.toString().trim() === "" && (proxyInfoData.city.trim() !== "" || proxyInfoData.state.toString().trim() !== "" || proxyInfoData.zip.trim() !== "" || proxyInfoData.address.toString().trim() !== "")) {
      isValid = false;
      handleClose();
      setProxyInfoError((proxyInfoError) => ({
        ...proxyInfoError,
        ["country_nameError"]: alerts.ENTER_COUNTRY,
      }));
    }

    if (currentPlan !== SubscriptionPlans.Basic && proxyInfoData?.lat.toString().trim() === "" && (proxyInfoData.city.trim() !== "" || proxyInfoData.state.toString().trim() !== "" || proxyInfoData.zip.trim() !== "" || proxyInfoData.address.toString().trim() !== "")) {
      isValid = false;
      handleClose();
      toast.error("Some error has occurred please try again.");

    }
    if (currentPlan !== SubscriptionPlans.Basic && proxyInfoData?.long.toString().trim() === "" && (proxyInfoData.city.trim() !== "" || proxyInfoData.state.toString().trim() !== "" || proxyInfoData.zip.trim() !== "" || proxyInfoData.address.toString().trim() !== "")) {
      isValid = false;
      handleClose();
      toast.error("Some error has occurred please try again.");
    }


    return isValid;

  }

  // Calling Basic info API
  const handleBasicInfoAPI = () => {
    let isValid = basicInfoValidation();
    if (isValid) {
      setEditable(!editable);
      setBasicInfoError(intitalFormErrorBasicInfo);
      editBasicInfo({
        first_name: basicInfoData.f_name,
        last_name: basicInfoData.l_name,
      });
      handleClose();
    }
  };

  // Calling Proxy info API
  const handleProxyAPI = () => {
    let isValid = validateproxy();
    if (isValid) {
      setEditableProxy(!editableProxy);
      setProxyInfoError(intitalValuesProxyAddressError);
      let proxyAddressobj = {}
      let result = zipCodeDataResponse

      if (proxyInfoData?.address !== proxyInfoDataResponse.address ||
        proxyInfoData?.city !== proxyInfoDataResponse.city ||
        proxyInfoData?.state !== proxyInfoDataResponse.state ||
        proxyInfoData?.country_id !== proxyInfoDataResponse.country_id ||
        proxyInfoData?.country_name !== proxyInfoDataResponse.country_name ||
        proxyInfoData?.zip_code !== proxyInfoDataResponse.zip_code ||
        proxyInfoData?.lat !== proxyInfoDataResponse.lat ||
        proxyInfoData?.long !== proxyInfoDataResponse.long
      ) {
        proxyAddressobj.address = proxyInfoData?.address
        proxyAddressobj.city = proxyInfoData?.city
        proxyAddressobj.state = proxyInfoData?.state
        proxyAddressobj.country_id = ""
        proxyAddressobj.country_name = proxyInfoData?.country_name
        proxyAddressobj.zip_code = proxyInfoData?.zip
        proxyAddressobj.type = proxyType
        proxyAddressobj.lat = proxyInfoData?.lat
        proxyAddressobj.long = proxyInfoData?.long
      }
      else {
        proxyAddressobj.type = proxyType
      }
      if (JSON.stringify(zipCodeDataResponse) !== JSON.stringify(zipcodes)) {
        // if(zipcodes[0] === ""){
        //   proxyAddressobj.zip_codes = []
        // }else{
        // }
        proxyAddressobj.zip_codes = zipcodes
        proxyAddressobj.type = proxyType
      }
      if (Object.keys(proxyAddressobj).length > 1) {
        addProxyAddress(proxyAddressobj)
      }
      handleClose();
    }
  };
  // Calling Bank info API

  const handleBankInfoAPI = () => {
    if (accountType === false) {
      editBankInfo({
        acc_type: 1,
        acc_name: bankData?.acc_name,
        routing_no: bankData?.routing_no,
        acc_number: bankData?.acc_number,
        remoteIp: ipAddress,
      });
    } else if (accountType === true) {
      editBankInfo({
        acc_type: 2,
        acc_name: bankData?.acc_name,
        routing_no: bankData?.routing_no,
        acc_number: bankData?.acc_number,
        remoteIp: ipAddress,
      });
    }
    setStripeLoading(true)
    setShowStripe(true)
    setBankInfoError(intitalFormErrorBankInfo);
    setEditableBank(!editableBank);
    handleClose();
  };

  // Calling Business info API

  const handleBusinessInfoAPI = () => {
    let isValid = businessInfoValidation();
    setUploadPicError("");
    if (isValid) {
      editBusinessInfo({
        business_name: businessInfoData.business_name,
        business_number: businessInfoData.business_number,
        business_address: businessInfoData.business_address,
        business_address2: businessInfoData.business_address2,
        business_city: businessInfoData.business_city,
        business_state: businessInfoData.business_state,
        zip_code: businessInfoData.zip_code,
        business_country: businessInfoData.business_country,
        business_description: businessInfoData.business_description,
        about: businessInfoData.about,
        site_link: businessInfoData.site_link,
        review_url: businessInfoData.review_url,
        find_us_on: businessInfoData.find_us_on,
        longitude: businessInfoData.longitude,
        latitude: businessInfoData.latitude,
        sellerpolicy_link: businessInfoData.business_sellerpolicy_link,
        sellerpolicy_text: businessInfoData.business_sellerpolicy_text,
        is_sellerpolicy: businessInfoData.business_is_sellerpolicy,
      });
      setBusinessInfoError(intitalFormErrorBusinessInfo);
      setEditableBusiness(false);
      handleClose();
    }
  };

  const handleBasicInfoChange = (event) => {
    setBasicInfoData((basicInfoData) => ({
      ...basicInfoData,
      [event.target.name]: event.target.value,
    }));
  };

  const handleBankInfoChange = (event) => {
    if (accountType) {
      if (
        event.target.name === "acc_number" ||
        event.target.name === "re_acc_number"
      ) {
        if (event.target.value.length === 0) {
          setBankData((bankData) => ({
            ...bankData,
            [event.target.name]: "",
          }));
        } else if (event.nativeEvent.inputType === "insertFromPaste") {
          setBankData((bankData) => ({
            ...bankData,
            [event.target.name]: event.target.value,
          }));
        } else if (
          event.target.value.length < bankData[event.target.name].length
        ) {
          let remainingString = bankData[event.target.name].slice(0, -1);
          setBankData((bankData) => ({
            ...bankData,
            [event.target.name]: remainingString,
          }));
        } else if (
          event.target.value.length > bankData[event.target.name].length
        ) {
          let lastChar = event.target.value.slice(-1);
          let remainingString = bankData[event.target.name].concat(lastChar);
          setBankData((bankData) => ({
            ...bankData,
            [event.target.name]: remainingString,
          }));
        }
      } else if (event.target.name === "routing_no") {
        if (event.target.value.length < bankData[event.target.name].length) {
          let remainingString = bankData[event.target.name].slice(0, -1);
          setBankData((bankData) => ({
            ...bankData,
            [event.target.name]: remainingString,
          }));
        } else {
          setBankData((bankData) => ({
            ...bankData,
            [event.target.name]: event.target.value,
          }));
        }

      }
      else {
        setBankData((bankData) => ({
          ...bankData,
          [event.target.name]: event.target.value,
        }));
        setBusinessAccount((businessAccount) => ({
          ...businessAccount,
          [event.target.name]: event.target.value,
        }));
      }
    } else if (!accountType) {
      if (
        event.target.name === "acc_number" ||
        event.target.name === "re_acc_number"
      ) {
        if (event.target.value.length === 0) {
          setBankData((bankData) => ({
            ...bankData,
            [event.target.name]: "",
          }));
        } else if (event.nativeEvent.inputType === "insertFromPaste") {
          setBankData((bankData) => ({
            ...bankData,
            [event.target.name]: event.target.value,
          }));
        } else if (
          event.target.value.length < bankData[event.target.name].length
        ) {
          let remainingString = bankData[event.target.name].slice(0, -1);
          setBankData((bankData) => ({
            ...bankData,
            [event.target.name]: remainingString,
          }));
        } else if (
          event.target.value.length > bankData[event.target.name].length
        ) {
          let lastChar = event.target.value.slice(-1);
          let remainingString = bankData[event.target.name].concat(lastChar);
          setBankData((bankData) => ({
            ...bankData,
            [event.target.name]: remainingString,
          }));
        }
      } else if (event.target.name === "routing_no") {
        if (event.target.value.length < bankData[event.target.name].length) {
          let remainingString = bankData[event.target.name].slice(0, -1);
          setBankData((bankData) => ({
            ...bankData,
            [event.target.name]: remainingString,
          }));
        } else {
          setBankData((bankData) => ({
            ...bankData,
            [event.target.name]: event.target.value,
          }));
        }

      }
      else {
        setPersonalAccount((personalAccount) => ({
          ...personalAccount,
          [event.target.name]: event.target.value,
        }));
        setBankData((bankData) => ({
          ...bankData,
          [event.target.name]: event.target.value,
        }));
      }
    }
  };
  const handleBusinessInfoChange = (event) => {
    if (event.target.name === "business_number") {
      if (/^[-\d\(\)\[\]\s]+$/.test(event.target.value)) {
        const numbers = event.target.value.match(/\d+/g);
        const fullNumber = numbers.join("");
        setBusinessInfoData((businessInfoData) => ({
          ...businessInfoData,
          [event.target.name]: fullNumber,
        }));
      } else if (!/\D/.test(event.target.value)) {
        setBusinessInfoData((businessInfoData) => ({
          ...businessInfoData,
          [event.target.name]: event.target.value,
        }));
      }
    } else if (event.target.name === "zip_code") {
      if (!/\D/.test(event.target.value)) {
        setBusinessInfoData((businessInfoData) => ({
          ...businessInfoData,
          [event.target.name]: event.target.value,
        }));
      }
    } else if (event.target.name === "business_address") {
      // initPlaceAPI(event.target.value);
      setBusinessInfoData((businessInfoData) => ({
        ...businessInfoData,
        [event.target.name]: event.target.value,
      }));
    }
    else if (event.target.name === "business_sellerpolicy_link" && event.target.value !== "") {
      setSellerPolicy(constants.STATUS_1)
      setBusinessInfoData((businessInfoData) => ({
        ...businessInfoData,
        [event.target.name]: event.target.value,
        ["business_sellerpolicy_text"]: "",
      }));
    }
    else if (event.target.name === "business_sellerpolicy_text" && event.target.value !== "") {
      setSellerPolicy(constants.STATUS_2)
      setBusinessInfoData((businessInfoData) => ({
        ...businessInfoData,
        [event.target.name]: event.target.value,
        ["business_sellerpolicy_link"]: "",
      }));
    }
    else {
      setSellerPolicy(0)
      setBusinessInfoData((businessInfoData) => ({
        ...businessInfoData,
        [event.target.name]: event.target.value,
      }));
    }
  };

  // handling Personal Account
  const personalAccType = () => {
    setAccountType(false);
    setBankInfoError(intitalFormErrorBankInfo);
    if (bankInfoData !== "" && bankInfoData.acc_type === constants.STATUS_1) {
      if (!editableBank) {
        if (bankInfoData.acc_number !== "" && bankInfoData.acc_number != null) {
          let maskedAccValue =
            "*".repeat(bankInfoData.acc_number.length)
          setPersonalAccount((personalAccount) => ({
            ...personalAccount,
            acc_name: bankInfoData.acc_name === null ? "" : bankInfoData.acc_name,
            re_acc_number: maskedAccValue,
            acc_number: maskedAccValue,
          }));
        }
      } else {
        setPersonalAccount((personalAccount) => ({
          ...personalAccount,
          routing_no: "",
          acc_name: bankInfoData.acc_name === null ? "" : bankInfoData.acc_name,
          acc_number: "",
          re_acc_number: ""
        }));
        setBankData((bankData) => ({
          ...bankData,
          acc_name: bankInfoData.acc_name === null ? "" : bankInfoData.acc_name,
          acc_type: bankInfoData.acc_type,
          routing_no: "",
          acc_number: "",
          re_acc_number: ""
        }));
      }
      setBusinessAccount(intitalValuesBankInfo);
    } else {
      setBankData(intitalValuesBankInfo);
      setPersonalAccount(intitalValuesBankInfo);
    }
  };

  // handling Business Account
  const businessAccType = () => {
    setAccountType(true);
    setBankInfoError(intitalFormErrorBankInfo);
    if (bankInfoData !== "" && bankInfoData.acc_type === constants.STATUS_2) {
      if (!editableBank) {
        if (bankInfoData.acc_number !== "" && bankInfoData.acc_number != null) {
          let maskedAccValue =
            "*".repeat(bankInfoData.acc_number.length)
          setBusinessAccount((businessAccount) => ({
            ...businessAccount,
            acc_name: bankInfoData.acc_name === null ? "" : bankInfoData.acc_name,
            re_acc_number: maskedAccValue,
            acc_number: maskedAccValue,
          }));
        }
      } else {
        setBusinessAccount((businessAccount) => ({
          ...businessAccount,
          routing_no: "",
          acc_name: bankInfoData.acc_name === null ? "" : bankInfoData.acc_name,
          acc_number: "",
          re_acc_number: ""
        }));
        setBankData((bankData) => ({
          ...bankData,
          acc_name: bankInfoData.acc_name === null ? "" : bankInfoData.acc_name,
          acc_type: bankInfoData.acc_type,
          routing_no: "",
          acc_number: "",
          re_acc_number: ""
        }));
      }

      setPersonalAccount(intitalValuesBankInfo);
    } else {
      setBankData(intitalValuesBankInfo);
      setBusinessAccount(intitalValuesBankInfo);
    }
  };

  const onChangeMaskAccPersonal = (data, type) => {
    if (data === "" || data === null || data.length < 12) {
      if (type === "accPersonal") {
        setPersonalAccount((personalAccount) => ({
          ...personalAccount,
          acc_number: data,
        }));
      } else {
        setPersonalAccount((personalAccount) => ({
          ...personalAccount,
          re_acc_number: data,
        }));
      }
    } else {
      let maskedAccValue = "*".repeat(data.length);
      if (type === "accPersonal") {
        setPersonalAccount((personalAccount) => ({
          ...personalAccount,
          acc_number: maskedAccValue,
        }));
      } else {
        setPersonalAccount((personalAccount) => ({
          ...personalAccount,
          re_acc_number: maskedAccValue,
        }));
      }
    }
  };
  const onChangeMaskAccBusiness = (data, type) => {
    if (data === "" || data === null || data.length < 12) {
      if (type === "accBusiness") {
        setBusinessAccount((businessAccount) => ({
          ...businessAccount,
          acc_number: data,
        }));
      } else {
        setBusinessAccount((businessAccount) => ({
          ...businessAccount,
          re_acc_number: data,
        }));
      }
    } else {
      let maskedAccValue = "*".repeat(data.length);
      if (type === "accBusiness") {
        setBusinessAccount((businessAccount) => ({
          ...businessAccount,
          acc_number: maskedAccValue,
        }));
      } else {
        setBusinessAccount((businessAccount) => ({
          ...businessAccount,
          re_acc_number: maskedAccValue,
        }));
      }
    }
  };

  const handleImageUpload = async (event) => {
    setUploadPicError("");
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      let fileType = event.target.files[0].type.split("/")[1];
      let isValid = validateFileTypes(file);
      if (isValid === "" && fileType !== 'pdf') {
        setUploadPic(true);
        const img = document.createElement("img");
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        img.onload = () => {
          let width = img.width;
          let height = img.height;
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          const dataUrl = canvas.toDataURL(file.type, 0.8);
          if (dataUrl) {
            // in file file upload of a product, product id will not be there
            imagePreSignedUpload({ fileName: dataUrl, fileType: fileType });
          }
        };
        img.src = URL.createObjectURL(file);
      } else if (isValid === "" && fileType === 'pdf') {
        setUploadPic(true);
        const reader = new FileReader();
        reader.onload = async (e) => {
          const fileData = e.target.result;
          // Handle PDF upload
          imagePreSignedUpload({ fileName: fileData, fileType: 'pdf' });
        };
        reader.readAsDataURL(file);
      }
      else {
        setUploadPicError(isValid);
        setUploadPic(false);
      }
    }
  };

  const handleSaveButton = () => {
    if (editable) {
      handleBasicInfoAPI();
    } else if (editableBank) {
      handleBankInfoAPI();
    } else if (editableBusiness) {
      handleBusinessInfoAPI();
    } else if (editableProxy) {
      handleProxyAPI()
    }
  };

  const phoneFormat = (value, type) => {
    let formattedPhoneNumber = value;
    if (value !== null) {
      if (value.length <= 3) {
        formattedPhoneNumber = value;
      } else if (value.length > 3 && value.length <= 6) {
        formattedPhoneNumber = `(${value.slice(0, 3)}) ${value.slice(3, 6)}`;
      } else if (value.length > 6) {
        formattedPhoneNumber = `(${value.slice(0, 3)}) ${value.slice(
          3,
          6
        )}-${value.slice(6, 10)}`;
      }
      if (type === constants.STATUS_1) {
        setBasicInfoPhoneNumber(formattedPhoneNumber);
      } else setBusinessInfoPhoneNumber(formattedPhoneNumber);
    }
  };


  //zipcode to lat and long
  const handleGetLocation = (zipcode) => {
    const requestURL = `${process.env.REACT_APP_USERS_API_URL}V1/mapsgeocode?postal_code=${zipcode}`;
    axios.get(requestURL, {
      headers: {
        'Api_key': process.env.REACT_APP_API_KEY_USER,
      }
    })
      .then((response) => {
        if (response.data.data.results.length > 0) {
          const result = response.data.data.results[0].geometry.location;
          setProxyInfoData((proxyInfoData) => ({
            ...proxyInfoData,
            lat: result.lat,
          }));
          setProxyInfoData((proxyInfoData) => ({
            ...proxyInfoData,
            long: result.lng,
          }));
        } else {
          // toast.error(alerts.ZIP_CODE_VALID);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
  const handleTabChanage = (key) => {
    setEditable(false);
    setEditableBank(false);
    setEditableBusiness(false);
    handleCancelProxyEdit()
    handleCancelBankEdit();
    handelCancelBusinessEdit();
    viewProxyAddress()
    vendorProfile()
    vendorSubscriptionDetails();
    vendorSubscriptionHistory();
    if (subscribed && !firstSubscribed && key !== "subscription" && key !== "payment_method") {
      toast.error(alerts.PLEASE_SUBSCRIBE_LOGIN)
    } else {
      setCurrentTab(key);
    }
  };

  const handleCancel = () => {
    if (currentTab === "current") {
      handleCancelBasicEdit();
    } else if (currentTab === "businessinfo") {
      handelCancelBusinessEdit();
    } else if (currentTab === "bankinfo") {
      handleCancelBankEdit();
    } else if (currentTab === "proxy") {
      handleCancelProxyEdit();
    }
  };

  const handleEdit = () => {
    if (currentTab === "current") {
      if (!editable) {
        handleBasicInfoEdit();
      } else {
        handleShow();
      }
    } else if (currentTab === "businessinfo") {
      if (!editableBusiness) {
        handleBusinessInfoEdit();
      } else {
        if (subscriptionDetails && subscriptionDetails?.is_premium === constants.STATUS_1 && businessInfoData.business_city !== '' && businessInfoData.business_city !== null && promotionalCities.length !== constants.STATUS_0 && !promotionalCities.includes(businessInfoData.business_city)) {
          handleShowRemoveSubscription()
        } else {
          handleShow();
        }
      }
    } else if (currentTab === "bankinfo") {
      if (!editableBank) {
        handleBankInfoEdit();
        vendorBankDetails()
      } else {
        let isValid = "";
        isValid = bankInfoValidation(bankData);
        if (isValid) {
          handleShow();
        }
      }
    } else if (currentTab === "proxy") {
      if (!editableProxy) {
        handleProxyEdit();
      } else {
        handleShow();
      }
    }
  };

  const deleteImage = (imageid) => {
    let params = {
      prod_id: vendorId,
      image_id: imageid,
    };
    removeBusinessImage(params);
    let currentImages =
      businessImage &&
      businessImage.filter((data, key) => {
        return data.image_id != imageid;
      });
    setbusinessImage(currentImages);
    fileupload();
  };

  const fileupload = () => {
    return (
      <FileUpload
        loading={uploadPic}
        profile={true}
        productImages={businessImage}
        enableEdit={!editableBusiness}
        uploadFile={(e) => handleImageUpload(e)}
        deleteImage={handleShowDelete}
      />
    );
  };

  const resetResponse = (action) => {
    dispatch({
      type: action,
      apiStatus: false,
      apiErrorCode: 0,
      apiResponse: {},
      apiMessage: "",
    });
  };

  const onChangeMaskrouting = (data, type) => {
    if (bankData.routing_no.length > 8) {
      let maskedRoutingValue =
        "*".repeat(bankData.routing_no.length)
      if (type === "personal") {
        setPersonalAccount((personalAccount) => ({
          ...personalAccount,
          routing_no: maskedRoutingValue,
        }));
      } else {
        setBusinessAccount((businessAccount) => ({
          ...businessAccount,
          routing_no: maskedRoutingValue,
        }));
      }
    } else {
      if (type === "personal") {
        setPersonalAccount((personalAccount) => ({
          ...personalAccount,
          routing_no: data,
        }));
      } else {
        setBusinessAccount((businessAccount) => ({
          ...businessAccount,
          routing_no: data,
        }));
      }
    }
  }

  const handleDeletePayment = (id) => {
    deltePaymentCard({
      cardId: id,
    });
  };

  const handleStripeVerification = () => {
    setShowStripe(false)
    window.open(isVerified, "_self");
  };

  const subscriptionCancel = () => {
    cancelSubscription()
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {alerts.VERIFICATION_LINK}
    </Tooltip>
  );

  const handleSaveDeletePayment = () => {
    handleDeletePayment(cardDetails?.id)
    setShowDeletePayment(false)
  }

  const handleZipcodeAdd = () => {
    if (currentPlan ===  SubscriptionPlans.Ultimate || currentPlan ===  SubscriptionPlans.Monthly) {
      refProxyZipcode.current.focus();
      if (zipcodes.length < 5) {
        setZipcodes(["", ...zipcodes])
        setZipcodesError([...zipcodesError, ""])
        if (zipcodes.length === 4) {
          setProxyZipLimit(true)
        }
      } else {
        setProxyZipLimit(true)
      }
    } else if (currentPlan ===  SubscriptionPlans.Advanced) {
      if (zipcodes.length < 2) {
        refProxyZipcode.current.focus();
        setZipcodes(["", ...zipcodes])
        setZipcodesError([...zipcodesError, ""])
        if (zipcodes.length === 1) {
          setProxyZipLimit(true)
        }
      } else {
        setProxyZipLimit(true)
      }
    } else if (currentPlan ===  SubscriptionPlans.Basic) {
      if (zipcodes.length < 1) {
        refProxyZipcode.current.focus();
        setZipcodes(["", ...zipcodes])
        setZipcodesError([...zipcodesError, ""])
      }
    }
  }

  const showDeletePincodePopUp = (index) => {
    if (zipcodes[index] !== "") {
      setDeletePincode(true)
      setZipCodeDeleteIndex(index)
    } else {
      if (zipcodes.length === constants.STATUS_1) {
        setZipcodes([""])
        refProxyZipcode.current.focus();
      } else {
        let array = [...zipcodes]
        array.splice(zipCodeDeleteIndex, 1)
        setZipcodes(array)
        refProxyZipcode.current.focus();
      }
      setProxyZipLimit(false)
    }
  }

  const handleZipcodeMinus = () => {
    if (zipcodes.length === constants.STATUS_1) {
      refProxyZipcode.current.focus();
      setZipcodes([""])
    } else {
      let array = [...zipcodes]
      array.splice(zipCodeDeleteIndex, 1)
      setZipcodes(array)
      refProxyZipcode.current.focus();
    }
    setProxyZipLimit(false)
    setDeletePincode(false)
  }

  const handleProxychange = (event) => {
    setProxyInfoData((proxyInfoData) => ({
      ...proxyInfoData,
      [event.target.name]: event.target.value,
    }));
  }

  const handleSellerPolicesLink = () => {
    setBusinessInfoData((businessInfoData) => ({
      ...businessInfoData,
      ["business_is_sellerpolicy"]: businessInfoData.business_is_sellerpolicy === constants.STATUS_1 ? constants.STATUS_0 : constants.STATUS_1,
      business_sellerpolicy_link: '',
      business_sellerpolicy_text: ''
    }));
    setBusinessInfoError((businessInfoError) => ({
      ...businessInfoError,
      site_linkError: '',
      site_textError: ''
    }));
  }
  const handleSellerPolicesDetails = () => {
    setBusinessInfoData((businessInfoData) => ({
      ...businessInfoData,
      ["business_is_sellerpolicy"]: businessInfoData.business_is_sellerpolicy === constants.STATUS_2 ? constants.STATUS_0 : constants.STATUS_2,
      business_sellerpolicy_link: '',
      business_sellerpolicy_text: ''
    }));
    setBusinessInfoError((businessInfoError) => ({
      ...businessInfoError,
      site_linkError: '',
      site_textError: ''
    }));
    setSellerPolicyType(true)
  }


  const handleCloseRemoveSubscription = () => {
    setShowRemoveSubscription(false)
  }
  const handleShowRemoveSubscription = () => {
    setShowRemoveSubscription(true)
  }

  const handleRemoveSubscription = () => {
    let isValid = businessInfoValidation();
    setUploadPicError("");
    if (isValid) {
      removeSubscription({
        subscribedId: subscriptionDetails?.subscriptionid
      })
      handleClose();
      handleCloseRemoveSubscription();
    }
  }

  const handleDiplayAddress = (event) => {
    setBusinessInfoData((businessInfoData) => ({ ...businessInfoData, 'business_address': event }));
  }
  const handleDiplayProxyAddress = (event) => {
    setProxyInfoData((proxyInfoData) => ({ ...proxyInfoData, 'address': event }));
  }
  return (
    <InnerLayout>
      <Col className="action-wrap">
        <Row>
          <Col lg="6" className="d-lg-flex align-items-lg-center">
            <h1>{Strings.Profile.Title}</h1>
          </Col>
          {currentTab !== "subscription" && currentTab !== "payment_method" && (
            <Col lg="6" className="text-lg-end">
              {(editable ||
                editableBusiness ||
                editableBank ||
                editableProxy) && (
                  <Button variant="outline-primary" onClick={handleCancel}>
                    {Strings.Profile.BasicInfo.Btn1}
                  </Button>
                )}
              {(editableBank &&
                currentTab === "bankinfo" && bankInfoData.acc_name !== null) && (
                  <Button variant="outline-primary" onClick={() => setDeleteBankinfoPopUp(true)} >
                    {Strings.Profile.BasicInfo.Btn3}
                  </Button>
                )}
              <Button variant="primary" onClick={handleEdit}>
                {!editable && !editableBusiness && !editableBank && !editableProxy ? bankInfoData.acc_name === null && !editableBank && currentTab == "bankinfo" && bankData.acc_name == "" ? "Add"
                  : currentTab == "proxy" && (
                    (currentPlan !== SubscriptionPlans.Basic && proxyType === constants.STATUS_1) ||
                    (currentPlan ===  SubscriptionPlans.Basic && zipcodes[0] !== "") ||
                    (currentPlan ===  SubscriptionPlans.Advanced && zipcodes.length < 2) ||
                    ((currentPlan ===  SubscriptionPlans.Ultimate|| currentPlan ===  SubscriptionPlans.Monthly)  && zipcodes.length < 5)) ? "Add" : Strings.Profile.BasicInfo.Btn2
                  : "Save"}
              </Button>
              {isVerified &&
                currentTab === "bankinfo" &&
                !showStripe &&
                !editableBank &&
                bankInfoData.acc_number !== null && (
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 150, hide: 150 }}
                    overlay={renderTooltip}
                  >
                    <Button
                      variant="primary"
                      onClick={handleStripeVerification}
                    >
                      {Strings.Profile.BankInfo.Btn3}
                    </Button>
                  </OverlayTrigger>
                )}
            </Col>
          )}
        </Row>
      </Col>
      {load ? <Loader/>
        :
        <Col className="inner-main profile">
          <Tabs
            defaultActiveKey="current"
            onSelect={handleTabChanage}
            activeKey={currentTab}
          >
            <Tab eventKey="current" title={Strings.Profile.BasicInfo.TabName}>
              <Row>
                <Col lg="4">
                  <Input
                    label={Strings.Profile.BasicInfo.Label1}
                    id="firstname"
                    name="f_name"
                    type="text"
                    value={basicInfoData.f_name}
                    onChange={handleBasicInfoChange}
                    errorMessage={basicInfoError.f_nameError}
                    readOnly={!editable}
                  />
                </Col>
                <Col lg="4">
                  <Input
                    label={Strings.Profile.BasicInfo.Label2}
                    id="lastname"
                    name="l_name"
                    value={basicInfoData?.l_name}
                    onChange={handleBasicInfoChange}
                    errorMessage={basicInfoError.l_nameError}
                    readOnly={!editable}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="4">
                  <Input
                    label={Strings.Profile.BasicInfo.Label3}
                    id="phone"
                    name="phone_number"
                    type="text"
                    onChange={handleBasicInfoChange}
                    errorMessage={basicInfoError.phone_numberError}
                    value={basicInfoPhoneNumber}
                    status={true}
                    readOnly
                  />
                </Col>
                <Col lg="4">
                  <Input
                    label={Strings.Profile.BasicInfo.Label4}
                    id="email"
                    name="e_address"
                    type="email"
                    onChange={handleBasicInfoChange}
                    errorMessage={basicInfoError.e_addressError}
                    value={basicInfoData?.e_address}
                    status={true}
                    readOnly
                  />
                </Col>
              </Row>
              <Col>
                <Link to="/changepassword">
                  {Strings.Profile.BasicInfo.ChangePass}
                </Link>
              </Col>
            </Tab>
            <Tab
              eventKey="businessinfo"
              title={Strings.Profile.BusinessInfo.TabName}
            >
              <Row>
                <Col lg="4">
                  <Input
                    label={Strings.Profile.BusinessInfo.Label1}
                    id="firstname"
                    type="text"
                    name="business_name"
                    maxLength="50"
                    onChange={handleBusinessInfoChange}
                    errorMessage={businessInfoError.business_nameError}
                    value={businessInfoData?.business_name}
                    readOnly={!editableBusiness}
                  />
                </Col>
                <Col lg="4">
                  <Input
                    label={Strings.Profile.BusinessInfo.Label2}
                    id="phone"
                    type="text"
                    name="business_number"
                    maxLength="14"
                    onChange={handleBusinessInfoChange}
                    onBlur={handleBusinessInfoChange}
                    errorMessage={businessInfoError.business_numberError}
                    value={businessInfoPhoneNumber}
                    readOnly={!editableBusiness}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="8">
                  <CustTextArea
                    label={Strings.Profile.BusinessInfo.Label3}
                    name="business_description"
                    maxLength="500"
                    onChange={handleBusinessInfoChange}
                    errorMessage={businessInfoError.business_descriptionError}
                    value={businessInfoData?.business_description}
                    readOnly={!editableBusiness}
                  />
                  <CustTextArea
                    label={Strings.Profile.BusinessInfo.Label4}
                    name="about"
                    maxLength="500"
                    onChange={handleBusinessInfoChange}
                    errorMessage={businessInfoError.aboutError}
                    value={businessInfoData.about ?? ""}
                    readOnly={!editableBusiness}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="4" className='reg-address' style={{ marginBottom: 13 }}>
                  <label>{Strings.Profile.BusinessInfo.Label5}</label>
                  <Autocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY}
                    style={{ width: "100%", borderColor: businessInfoError.business_addressError !== '' ? "red" : "" }}
                    onPlaceSelected={(place) => {
                      setBusinessInfoData((businessInfoData) => ({
                        ...businessInfoData,
                        business_address: "",
                        business_address2: "",
                        business_city: "",
                        business_state: "",
                        zip_code: "",
                        country: "",
                      }));
                      setPlace(place.address_components);
                      setLat(place.geometry.location.lat());
                      setLong(place.geometry.location.lng());

                    }}
                    onChange={(e) => handleDiplayAddress(e.target.value)}
                    value={businessInfoData?.business_address}
                    options={{
                      fields: ['formatted_address', 'address_components', 'geometry', 'name'],
                      strictBounds: false,
                      types: ['geocode', 'establishment'],
                      componentRestrictions: { country: 'us' }
                    }}
                    disabled={!editableBusiness}
                  />
                  {businessInfoError.business_addressError && businessInfoError.business_addressError !== '' ? <Form.Text style={{ color: "red" }}>
                    {businessInfoError.business_addressError}
                  </Form.Text> : ""}
                </Col>
                <Col lg="4">
                  <Input
                    label={Strings.Profile.BusinessInfo.Label6}
                    id="address2"
                    type="text"
                    name="business_address2"
                    maxLength="150"
                    //onChange={handleBusinessInfoChange}
                    errorMessage={businessInfoError.business_address2Error}
                    value={businessInfoData?.business_address2}
                    readOnly={!editableBusiness}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="4">
                  <Input
                    label={Strings.Profile.BusinessInfo.Label7}
                    id="city"
                    name="business_city"
                    maxLength={100}
                    //onChange={handleBusinessInfoChange}
                    errorMessage={businessInfoError.business_cityError}
                    value={businessInfoData?.business_city}
                    type="text"
                    readOnly={!editableBusiness}
                  />
                </Col>
                <Col lg="4">
                  <Input
                    label={Strings.Profile.BusinessInfo.Label8}
                    id="state"
                    name="business_state"
                    //onChange={handleBusinessInfoChange}
                    errorMessage={businessInfoError.business_stateError}
                    value={businessInfoData?.business_state}
                    type="text"
                    readOnly={!editableBusiness}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="4">
                  <Input
                    label={Strings.Profile.BusinessInfo.Label9}
                    id="zip"
                    name="zip_code"
                    maxLength={constants.ZIPCODE_LENGTH}
                    //onChange={handleBusinessInfoChange}
                    errorMessage={businessInfoError.zip_codeError}
                    value={businessInfoData?.zip_code}
                    type="text"
                    readOnly={!editableBusiness}
                  />
                </Col>
                <Col lg="4">
                  <Input
                    label={Strings.Profile.BusinessInfo.Label10}
                    id="country"
                    name="business_country"
                    //onChange={handleBusinessInfoChange}
                    errorMessage={businessInfoError.business_countryError}
                    value={
                      businessInfoData.business_country === constants.STATUS_1
                        ? "United States"
                        : businessInfoData?.business_country
                    }
                    type="text"
                    readOnly={!editableBusiness}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <div className="uploaded-files">
                    <h3>{Strings.Profile.BusinessInfo.Title1}</h3>
                  </div>
                  <p>
                    {uploadPicError && uploadPicError != "" ? (
                      <Form.Text className="red">{uploadPicError}</Form.Text>
                    ) : (
                      ""
                    )}
                  </p>
                  {fileupload()}
                </Col>
                <Col lg="6">
                  <h3>{Strings.Profile.BusinessInfo.Title2}</h3>
                  <WebLink
                    id="site_link"
                    maxLength={150}
                    name="site_link"
                    label={Strings.Profile.BusinessInfo.Label11}
                    value={businessInfoData?.site_link}
                    onChange={handleBusinessInfoChange}
                    readOnly={!editableBusiness}
                    errorMessage={businessInfoError.web_LinkError}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <h3>{Strings.Profile.BusinessInfo.Title3}</h3>
                  <WebLink
                    id="site_link"
                    maxLength={150}
                    name="find_us_on"
                    label={Strings.Profile.BusinessInfo.Label11}
                    value={businessInfoData?.find_us_on}
                    onChange={handleBusinessInfoChange}
                    readOnly={!editableBusiness}
                    errorMessage={businessInfoError.find_us_onError}
                  />
                </Col>
                <Col lg="6">
                  <h3>{Strings.Profile.BusinessInfo.Title4}</h3>
                  <WebLink
                    id="site_link"
                    maxLength={150}
                    name="review_url"
                    label={Strings.Profile.BusinessInfo.Label11}
                    value={businessInfoData?.review_url}
                    onChange={handleBusinessInfoChange}
                    readOnly={!editableBusiness}
                    errorMessage={businessInfoError.review_urlError}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <h3>{Strings.Profile.BusinessInfo.Title6}</h3>
                  <div className="policy-type">
                    <Form.Check
                      type="radio"
                      aria-label="radio 1"
                      label={Strings.Profile.BusinessInfo.Label12}
                      onClick={() => handleSellerPolicesLink()}
                      id="radio1"
                      inline
                      // checked={sellerPolicy === constants.STATUS_1 || sellerPolicy === 0 ? constants.STATUS_TRUE : constants.STATUS_FALSE}
                      checked={businessInfoData.business_is_sellerpolicy === constants.STATUS_1 ? constants.STATUS_TRUE : constants.STATUS_FALSE}
                      disabled={!editableBusiness}
                    />
                    <Form.Check
                      type="radio"
                      aria-label="radio 2"
                      label={Strings.Profile.BusinessInfo.Label13}
                      onClick={() => handleSellerPolicesDetails()}
                      id="radio2"
                      inline
                      // checked={sellerPolicy === constants.STATUS_2 ? constants.STATUS_TRUE : constants.STATUS_FALSE }
                      checked={businessInfoData.business_is_sellerpolicy === constants.STATUS_2 ? constants.STATUS_TRUE : constants.STATUS_FALSE}
                      disabled={!editableBusiness}
                    />
                  </div>

                  {(businessInfoData.business_is_sellerpolicy === constants.STATUS_1) && <Input
                    id="policylink"
                    name="business_sellerpolicy_link"
                    label={Strings.Profile.BusinessInfo.Label14}
                    type="text"
                    onChange={handleBusinessInfoChange}
                    value={businessInfoData?.business_sellerpolicy_link}
                    errorMessage={businessInfoError.site_linkError}
                    readOnly={!editableBusiness}
                  />}
                  {businessInfoData.business_is_sellerpolicy === constants.STATUS_2 && <CustTextArea
                    label={Strings.Profile.BusinessInfo.Label15}
                    name="business_sellerpolicy_text"
                    maxLength="1000"
                    onChange={handleBusinessInfoChange}
                    value={businessInfoData?.business_sellerpolicy_text}
                    errorMessage={businessInfoError.site_textError}
                    readOnly={!editableBusiness}
                  />}
                </Col>
              </Row>
            </Tab>
            <Tab
              eventKey="bankinfo"
              title={
                <React.Fragment>
                  {Strings.Profile.BankInfo.TabName}
                  {stripeVerified !== "" && (
                    <span className="verify-info position-absolute">
                      <img
                        src={stripeVerified ? VerifiedAcc : PendingAcc}
                        alt=""
                      />
                    </span>
                  )}
                </React.Fragment>
              }
              tabClassName="position-relative"
            >
              {bankInfoData.acc_name === null &&
                !editableBank &&
                currentTab == "bankinfo" &&
                bankData.acc_name == "" ? (
                <>
                  <Col className="text-center">
                    <h3 className="mt-0">
                      {Strings.Profile.BusinessInfo.Title5}
                    </h3>
                    <Button variant="primary" onClick={handleEdit}>
                      {Strings.Profile.BusinessInfo.Btn3}
                    </Button>
                  </Col>
                </>
              ) : (
                <>
                  <h3 className="mt-0">{Strings.Profile.BankInfo.Title}</h3>
                  <Col className="account-type d-flex">
                    <Form.Check
                      type="radio"
                      aria-label="radio 1"
                      label={Strings.Profile.BankInfo.Label1}
                      onClick={() => {
                        personalAccType();
                      }}
                      name="bank"
                      id="radio1"
                      defaultChecked
                      checked={!accountType}
                      disabled={!editableBank}
                    />
                    <Form.Check
                      type="radio"
                      aria-label="radio 2"
                      label={Strings.Profile.BankInfo.Label2}
                      onClick={() => {
                        businessAccType();
                      }}
                      name="bank"
                      id="radio2"
                      checked={accountType}
                      disabled={!editableBank}
                    />
                  </Col>
                  <Row>
                    <Col lg="4">
                      <Input
                        label={Strings.Profile.BankInfo.Label3}
                        id="accountname"
                        type="text"
                        name="acc_name"
                        onChange={handleBankInfoChange}
                        errorMessage={bankInfoError.acc_nameError}
                        value={
                          accountType === constants.STATUS_TRUE
                            ? businessAccount.acc_name
                            : personalAccount.acc_name
                        }
                        readOnly={!editableBank}
                      />
                    </Col>
                    {editableBank && (
                      <Col lg="4">
                        <Input
                          label={Strings.Profile.BankInfo.Label4}
                          id="routing"
                          type="text"
                          name="routing_no"
                          onChange={handleBankInfoChange}
                          maxLength={9}
                          errorMessage={bankInfoError.routing_noError}
                          value={
                            accountType === constants.STATUS_TRUE
                              ? businessAccount.routing_no
                              : personalAccount.routing_no
                          }
                          readOnly={!editableBank}
                        />
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col lg="4">
                      <Input
                        label={Strings.Profile.BankInfo.Label5}
                        id="account"
                        type="text"
                        name="acc_number"
                        maxLength="12"
                        autoComplete="off"
                        value={
                          accountType === constants.STATUS_TRUE
                            ? businessAccount.acc_number
                            : personalAccount.acc_number
                        }
                        onChange={handleBankInfoChange}
                        errorMessage={bankInfoError.acc_numberError}
                        readOnly={!editableBank}
                      />
                    </Col>
                    {editableBank && (
                      <Col lg="4">
                        <Input
                          label={Strings.Profile.BankInfo.Label6}
                          id="re_acc_number"
                          type="text"
                          name="re_acc_number"
                          maxLength="12"
                          autoComplete="off"
                          value={
                            accountType === constants.STATUS_TRUE
                              ? businessAccount.re_acc_number
                              : personalAccount.re_acc_number
                          }
                          onChange={handleBankInfoChange}
                          errorMessage={bankInfoError.re_acc_numberError}
                          readOnly={!editableBank}
                        />
                      </Col>
                    )}
                  </Row>
                </>
              )}
            </Tab>
            <Tab
              eventKey="subscription"
              title={Strings.Profile.Subscription.TabName}
            >
              {!showSubscriptionHistory &&
                (upgradePlanClicked ||
                  subscriptionDetails == "" ||
                  subscriptionDetails.cancel_at_period_end) ? (
                <Col>
                  <Row>
                    {subscriptionListData &&
                      subscriptionListData.level == "error" ? (
                      ""
                    ) : subscriptionListData &&
                      subscriptionListData.length > 0 ? (
                      <>
                        <div className="text-end mb-4">
                          <Button
                            disabled={firstSubscribed}
                            variant="primary"
                            onClick={() => setShowSubscriptionHistory(true)}
                          >
                            {Strings.Profile.Btn}
                          </Button>
                        </div>
                        {subscriptionListData.map((data, key) => {
                          return (
                            <Plan
                              className={
                                data.plan_id === SubscriptionPlans.Basic
                                  ? "plan-outer tier1"
                                  : data.plan_id === SubscriptionPlans.Advanced
                                    ? "plan-outer tier2"
                                    : data.plan_id === SubscriptionPlans.Ultimate
                                      ? "plan-outer tier4"
                                      : data.plan_id === SubscriptionPlans.Monthly
                                      ? "plan-outer tier3" :""
                              }
                              data={data}
                              key={key}
                              subscriptionInfo={subscriptionInfoData}
                              planData={planData}
                              setPlanData={setPlanData}
                              cardDetails={cardDetails}
                              subscribed={subscribed}
                              firstSubscribed={firstSubscribed}
                              subscriptionStatus={
                                subscriptionDetails.subscriptionStatus
                              }
                              subscriptionDetails={subscriptionDetails}
                              currentPlan={() => setUpgradePlanClicked(false)}
                              setLoadingModal={setLoadingModal}
                              setCurrentTab={setCurrentTab}
                            />
                          );
                        })}
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                </Col>
              ) : (
                <Col>
                  <Row>
                    <PlanSubscribed
                      planName={subscriptionDetails.planName}
                      planCost={subscriptionDetails.planCost}
                      endDate={subscriptionDetails.subscriptionEndDate}
                      validFrom={subscriptionDetails.subscriptionStartDate}
                      planType={subscriptionDetails.planType}
                      planid={subscriptionDetails.planid}
                      lookupKey={subscriptionDetails.lookupKey}
                      canceled={subscriptionDetails.cancel_at_period_end}
                      upcoming={subscriptionInfoData?.upcoming_type}
                      subscriptionStatus={subscriptionDetails.subscriptionStatus}
                      cancelSubscription={() => setShowcancelSubscription(true)}
                      subscriptionHistory={subscriptionHistory}
                      upgradePlan={upgradeClick}
                      subscriptionListData={subscriptionListData}
                    />
                  </Row>
                </Col>
              )}
            </Tab>
            <Tab
              eventKey="payment_method"
              title={Strings.Profile.PaymentMethod.TabName}
            >
              {cardDetails && cardDetails !== "" ? (
                <Row>
                  <Col xl="4" md="8">
                    <div className="saved-cards">
                      <p>{cardDetails?.card_brand}</p>
                      <p className="card-number">
                        XXXX XXXX XXXX {cardDetails?.last_four}
                      </p>
                      <div className="d-flex justify-content-between">
                        <p className="mb-0">
                          Expires: {cardDetails?.exp_month}/
                          {cardDetails?.exp_year}
                        </p>
                        <Link to="#" onClick={() => setShowDeletePayment(true)}>
                          {Strings.Profile.PaymentMethod.Btn1}
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Elements stripe={stripePromise} options={{ appearance }}>
                  <CardForm setCardSave={setCardSave} cardSave={cardSave} />
                </Elements>
              )}
            </Tab>
            <Tab eventKey="proxy" title={Strings.Profile.ProxyAddress.TabName}>
              {currentPlan !== SubscriptionPlans.Basic &&
                <>
                  <h3 className="mt-0">{Strings.Profile.ProxyAddress.Title}</h3>
                  <p>{Strings.Profile.ProxyAddress.Label7}</p>
                  <Row>
                    <Col lg="8" className='reg-address' style={{ marginBottom: 13 }}>
                      <label>{Strings.Profile.ProxyAddress.Label1}</label>
                      <Autocomplete
                        apiKey={process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY}
                        style={{ width: "100%", borderColor: proxyInfoError.addressError !== '' ? "red" : "" }}
                        onPlaceSelected={(place) => {
                          setProxyInfoData((proxyInfoData) => ({
                            ...proxyInfoData,
                            address: "",
                            country_name: "",
                            state: "",
                            zip: "",
                            city: "",
                            country_code: "",
                            country_id: "",
                            lat: place.geometry.location.lat(),
                            long: place.geometry.location.lng()
                          }));
                          setPlaceProxy(place.address_components);
                        }}
                        onChange={(e) => handleDiplayProxyAddress(e.target.value)}
                        value={proxyInfoData?.address}
                        options={{
                          fields: ['formatted_address', 'address_components', 'geometry', 'name'],
                          strictBounds: false,
                          types: ['geocode', 'establishment'],
                          componentRestrictions: { country: 'us' }
                        }}
                        disabled={!editableProxy || currentPlan ===  SubscriptionPlans.Basic}
                      />
                      {proxyInfoError.addressError && proxyInfoError.addressError !== '' ? <Form.Text style={{ color: "red" }}>
                        {proxyInfoError.addressError}
                      </Form.Text> : ""}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="4">
                      <Input
                        label={Strings.Profile.ProxyAddress.Label2}
                        id="city"
                        type="text"
                        name="city"
                        maxLength="150"
                        errorMessage={proxyInfoError.cityError}
                        value={proxyInfoData?.city}
                        //onChange={handleProxychange}
                        readOnly={!editableProxy || currentPlan ===  SubscriptionPlans.Basic}
                      />
                    </Col>
                    <Col lg="4">
                      <Input
                        label={Strings.Profile.ProxyAddress.Label3}
                        id="state"
                        type="text"
                        name="state"
                        maxLength="150"
                        errorMessage={proxyInfoError.stateError}
                        value={proxyInfoData?.state}
                        //onChange={handleProxychange}
                        readOnly={!editableProxy || currentPlan ===  SubscriptionPlans.Basic}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="4">
                      <Input
                        label={Strings.Profile.ProxyAddress.Label4}
                        id="zip"
                        type="text"
                        name="zip"
                        maxLength={constants.ZIPCODE_LENGTH}
                        errorMessage={proxyInfoError.zipError}
                        value={proxyInfoData?.zip}
                        //onChange={handleProxychange}
                        readOnly={!editableProxy || currentPlan ===  SubscriptionPlans.Basic}
                      />
                    </Col>
                    <Col lg="4">
                      <Input
                        label={Strings.Profile.ProxyAddress.Label5}
                        id="country_name"
                        type="text"
                        name="country_name"
                        maxLength="150"
                        errorMessage={proxyInfoError.country_nameError}
                        value={proxyInfoData?.country_name}
                        //onChange={handleProxychange}
                        readOnly={!editableProxy || currentPlan ===  SubscriptionPlans.Basic}
                      />
                    </Col>
                  </Row>
                </>
              }
              <h3>{Strings.Profile.ProxyAddress.Title2}</h3>
              <p>{Strings.Profile.ProxyAddress.Label8}</p>
              {zipcodes.map((input, index) => {
                return (
                  <Row>
                    <Col lg="4">
                      <Input
                        label={Strings.Profile.ProxyAddress.Label6}
                        value={input}
                        maxLength={constants.ZIPCODE_LENGTH}
                        onChange={(e) => {
                          const newInputs = [...zipcodes];
                          newInputs[index] = e.target.value;
                          setZipcodes(newInputs);
                        }}
                        txtRef={index === 0 ? refProxyZipcode : null}
                        errorMessage={zipcodesError[index]}
                        readOnly={!editableProxy}
                      />
                    </Col>
                    {editableProxy ? <Col lg="4">
                      {currentPlan !== SubscriptionPlans.Basic && index === 0 && !proxyZipLimit && zipcodes.length !== zipcodeLimit ? <button
                        // className={(!editableProxy || proxyZipLimit || currentPlan === SubscriptionPlans.Basic|| (currentPlan === (0 || null))) ? "add-disable-zip" : "add-zip"}
                        className={"add-zip"}
                        disabled={!editableProxy || proxyZipLimit || currentPlan === SubscriptionPlans.Basic|| (currentPlan === (0 || null))}
                        onClick={() => handleZipcodeAdd()}
                      >
                        <AddProxy />
                      </button> : ""}
                      {currentPlan !== SubscriptionPlans.Basic && zipcodes.length !== 1 || zipcodes[0] !== "" ? <button
                        className={"add-zip"}
                        // className={!editableProxy || (zipcodes.length === constants.STATUS_1 && zipcodes[0] === "") ? "add-disable-zip" : "add-zip"}
                        disabled={!editableProxy || (zipcodes.length === constants.STATUS_1 && zipcodes[0] === "")}
                        onClick={() => showDeletePincodePopUp(index)}
                      >
                        <RemoveProxy />
                      </button> : ""}
                    </Col> : ""}
                  </Row>
                );
              })}

            </Tab>
          </Tabs>
        </Col>
      }
      <Modal
        show={show}
        onHide={handleClose}
        size="l"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <h5 className="text-center">
            {editableProxy &&
              proxyInfoDataResponse.address !== "" &&
              proxyInfoDataResponse.city !== "" &&
              proxyInfoDataResponse.state !== "" &&
              proxyInfoDataResponse.zip !== "" &&
              proxyInfoDataResponse.country_name !== "" &&

              proxyInfoData.address === "" &&
              proxyInfoData.city === "" &&
              proxyInfoData.state === "" &&
              proxyInfoData.zip === "" &&
              proxyInfoData.country_name === ""
              ?
              alerts.PROXY_ADDRESS_DELETE :
              alerts.SAVE_PROFILE}
          </h5>
          {/* <h5 className="text-center">{alerts.SAVE_PROFILE}</h5> */}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="outline-primary" onClick={handleClose}>
            {Strings.Profile.Btn1}
          </Button>
          <Button variant="primary" onClick={handleSaveButton}>
            {Strings.Profile.Btn2}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showcancelSubscription}
        onHide={cancelSubscriptionClose}
        centered
        backdrop="static"
      >
        <Modal.Body className="text-center">
          <h5>{Strings.SbuscriptionCancel.Title}</h5>
          {console.log(subscriptionDetails.SubscriptionPlans)}
          {subscriptionInfoData && subscriptionInfoData.upcoming_type !== 0 ? (
            subscriptionListData &&
            subscriptionListData.length > 0 &&
            subscriptionListData.map((data, key) => {
              if (data.plan_id === subscriptionInfoData.upcoming_type) {
                return (
                  <PlanCancel
                    planCost={Math.trunc(data.plan_amount)}
                    planName={data.plan_name}
                    planType={data.plan_id === SubscriptionPlans.Monthly ? Strings.Profile.Subscription.Label10 : Strings.Profile.Subscription.Label6}
                    lookupKey={subscriptionDetails.lookupKey}
                    className={
                      data.plan_id === SubscriptionPlans.Basic
                        ? "plan-cancel tier1 text-center d-flex flex-column justify-content-center mt-4"
                        : data.plan_id === SubscriptionPlans.Advanced
                          ? "plan-cancel tier2 text-center d-flex flex-column justify-content-center mt-4"
                          : data.plan_id === SubscriptionPlans.Ultimate
                            ? "plan-cancel tier4 text-center d-flex flex-column justify-content-center mt-4"
                            : data.plan_id === SubscriptionPlans.Monthly
                            ? "plan-cancel tier3 text-center d-flex flex-column justify-content-center mt-4"
                            :""
                    }
                    isUpComing={true}
                  />
                );
              }
            })
          ) : (
            <PlanCancel
              planCost={Math.trunc(subscriptionDetails.planCost)}
              planName={subscriptionDetails.planName}
              planType={subscriptionDetails.planType}
              lookupKey={subscriptionDetails.lookupKey}
              className={
                subscriptionDetails.planid === SubscriptionPlans.Basic
                  ? "plan-cancel tier1 text-center d-flex flex-column justify-content-center mt-4"
                  : subscriptionDetails.planid === SubscriptionPlans.Advanced
                    ? "plan-cancel tier2 text-center d-flex flex-column justify-content-center mt-4"
                    : subscriptionDetails.planid === SubscriptionPlans.Ultimate
                      ? "plan-cancel tier4 text-center d-flex flex-column justify-content-center mt-4"
                      : subscriptionDetails.planid === SubscriptionPlans.Monthly
                      ? "plan-cancel tier3 text-center d-flex flex-column justify-content-center mt-4"
                      : ""
              }
              isUpComing={false}
            />
          

          )
          }
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="outline-primary" onClick={cancelSubscriptionClose}>
            {Strings.SbuscriptionCancel.Btn2}
          </Button>
          <Button variant="primary" onClick={subscriptionCancel}>
            {Strings.SbuscriptionCancel.Btn1}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showStripe}
        onHide={() => setShowStripe(false)}
        size="l"
        centered
        backdrop="static"
        className={stripeLoading && "loader-pop"}
      >
        {stripeLoading ? (
          <div className=" align-items-center justify-content-center d-flex">
            <Spinner animation="border" variant="light" />
          </div>
        ) : (
          <>
            <Modal.Body>
              <h5 className="text-center">{Strings.SubscriptionInfo.Verify}</h5>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <Button
                variant="outline-primary"
                onClick={() => setShowStripe(false)}
              >
                {Strings.Profile.BankInfo.Btn1}
              </Button>

              <Button variant="primary" onClick={handleStripeVerification}>
                {Strings.Profile.BankInfo.Btn3}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
      <Modal
        show={showDeletePayment}
        onHide={() => setShowDeletePayment(false)}
        size="l"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <h5 className="text-center">
            {Strings.Profile.PaymentMethod.Label3}
          </h5>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="outline-primary"
            onClick={() => setShowDeletePayment(false)}
          >
            {Strings.Profile.PaymentMethod.Btn}
          </Button>
          <Button variant="primary" onClick={handleSaveDeletePayment}>
            {Strings.Profile.PaymentMethod.Btn3}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={loadingModal}
        onHide={() => setLoadingModal(false)}
        size="l"
        centered
        backdrop="static"
        className={"loader-pop"}
      >
        <div className=" align-items-center justify-content-center d-flex">
          <Spinner animation="border" variant="light" />
        </div>
      </Modal>

      <Modal
        show={deletePinCode}
        onHide={() => setDeletePincode(false)}
        size="l"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <h5 className="text-center">
            {alerts.DELETE_ZIPCODE}
          </h5>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="outline-primary"
            onClick={() => setDeletePincode(false)}
          >
            {Strings.Profile.PaymentMethod.Btn}
          </Button>
          <Button variant="primary"
            onClick={handleZipcodeMinus}
          >
            {Strings.Profile.PaymentMethod.Btn3}
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal
        show={deleteBankinfoPopUp}
        onHide={() => setDeleteBankinfoPopUp(false)}
        size="l"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <h5 className="text-center">
            {alerts.DELETE_BANKINFO_MESSAGE}
          </h5>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="outline-primary"
            onClick={() => setDeleteBankinfoPopUp(false)}
          >
            {Strings.Profile.PaymentMethod.Btn}
          </Button>
          <Button variant="primary"
            onClick={() => setDeleteBankinfoClicked(true)}
          >
            {Strings.Profile.BankInfo.Btn4}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDelete} onHide={handleCloseDelete} size="l" centered backdrop="static">
        <Modal.Body >
          <h5 className="text-center"> {alerts.DELETE_CONFIRMATION} </h5>
        </Modal.Body>
        <Modal.Footer className='justify-content-center' >
          <Button variant="outline-primary" onClick={handleCloseDelete}>Cancel</Button>
          <Button variant="primary" onClick={handleDeleteButton}>OK</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showRemoveSubscription} onHide={handleCloseRemoveSubscription} size="l" centered backdrop="static">
        <Modal.Body >
          <h5 className="text-center"> {alerts.PROMOTIONAL_CITY_CHANGE_WARNING} </h5>
        </Modal.Body>
        <Modal.Footer className='justify-content-center' >
          <Button variant="outline-primary" onClick={handleCloseRemoveSubscription}>Cancel</Button>
          <Button variant="primary" onClick={handleRemoveSubscription}>OK</Button>
        </Modal.Footer>
      </Modal>
    </InnerLayout>
  );
};
export default Profile;
