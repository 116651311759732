/**
 * @file   src\
 * @brief  This file is responsible for
 * @date   Nov, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate, useLocation } from "react-router-dom";
import Payments from "../components/Payments";

const Payment = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  const options = {
    // passing the client secret obtained from the server
    clientSecret: location.state && location.state.client_secret ? location.state.client_secret : "",
  };
  return (
    options && options.clientSecret? <Elements stripe={stripePromise} options={options}>
      <Payments
        amount={location.state && location.state.amount ? location.state.amount : 0}
        is_upgrade={location.state && location.state.is_upgrade ? location.state.is_upgrade : 0}
        plan_id={location.state && location.state.plan_id ? location.state.plan_id : 0}
        type={location.state && location.state.type ? location.state.type : 0}
        subscriptionId={location.state && location.state.subscriptionId ? location.state.subscriptionId : 0}
        lookupkey={location.state && location.state.lookupkey ? location.state.lookupkey : 0} />
    </Elements>:navigate("/")
  );
};
export default Payment;
