/**
 * @file   src\
 * @brief  This file is responsible for 
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import { Button, Col, Row, Table, Modal, Badge } from 'react-bootstrap'
import InnerLayout from '../components/InnerLayout'
import Strings from '../assets/strings/Strings.json'
import PdfIcn from '../assets/img/icons/Pdf'
import SearchInput from '../components/SearchInput'
import CustSelect from '../components/Select'
import CustSelectLabel from '../components/SelectLabel'
import Filter from '../assets/img/icons/Filter'
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { _purchaseHistory, _vendorCategoryList, _vendorExportPurchaseHistory, resetResponse } from '../actions/VendorAction';
import { dateToMMMDDYYYY, formatPhoneNumber } from '../helpers/validationHelper';
import Paginate from '../components/Paginate'
import { toast } from 'react-toastify';
import * as alerts from '../utils/Messages'
import NoImage from "../assets/img/avatar.svg";
import { propTypes } from 'react-bootstrap/esm/Image'
import * as constants from '../utils/constants';
import { Link, useNavigate } from 'react-router-dom';
import BusinessDetails from '../components/BusinessDetails';
import User from '../assets/img/icons/User';
import Phone from '../assets/img/icons/Phone';
import Mail from '../assets/img/icons/Mail';
import Address from '../assets/img/icons/Address';
import ICShipmentInfo from '../assets/img/icons/ShipmentInfo'
import Loader from '../components/Loader';

const PurchaseHistory = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getFormattedTime = (time) => {
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;
    const formattedDate = dateToMMMDDYYYY(time)
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedDate} at ${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  //purchase history starts
  const purchaseHistoryList = (purchaseHistoryListParams) => dispatch(_purchaseHistory(purchaseHistoryListParams));
  const purchaseHistoryResponse = useSelector((RootReducer) => RootReducer.VendorReducer.purchaseHistoryResponse)
  //purchase history ends

  // category list start
  const categoryList = () => dispatch(_vendorCategoryList());
  const categoryListApiResponse = useSelector((RootReducer) => RootReducer.VendorReducer.vendorCategoryListResponse);
  // category list ends

  // export purchase history start
  const exportPurchaseHistory = (params) => dispatch(_vendorExportPurchaseHistory(params));
  const exportPurchaseHistoryResponse = useSelector((RootReducer) => RootReducer.VendorReducer.vendorExportPurchaseHistoryResponse);
  // export purchase history ends

  const initialParams = {
    "page": 1,
    "search_key": "",
    "category_id": "",
    "p_Month": "",
    "p_Year": "",
    "client_time": getFormattedTime(new Date())
  }

  const monthOptions = [
    { "label": "January", "value": "1" },
    { "label": "February", "value": "2" },
    { "label": "March", "value": "3" },
    { "label": "April", "value": "4" },
    { "label": "May", "value": "5" },
    { "label": "June", "value": "6" },
    { "label": "July", "value": "7" },
    { "label": "August", "value": "8" },
    { "label": "September", "value": "9" },
    { "label": "October", "value": "10" },
    { "label": "November", "value": "11" },
    { "label": "December", "value": "12" },
  ]

  const intitalPurchaseHistoryFilters = {
    p_Month: "",
    p_Year: "",
  };


  const [purchaseHistoryListParams, setPurchaseHistoryListParams] = useState(initialParams);
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [downloadUrl, setDownloadUrl] = useState("")
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [purchaseHistoryFilters, setPurchaseHistoryFilters] = useState(intitalPurchaseHistoryFilters);
  const [filtershow, setFilterShow] = useState(false);
  const [trackingshow, setTrackingShow] = useState(false);
  const [startDateFilterError, setStartDateFilterError] = useState('')
  const [year, setYear] = useState([])
  const [showUserInfo, setShowUserInfo] = useState(false)
  const [userDetails, setUserDetails] = useState("")
  const [acceptedClass, setAcceptedClass] = useState('');
  const [inTransitClass, setInTransitClass] = useState('');
  const [deliveredClass, setDeliveredClass] = useState('');
  
 
  const [load, setLoad] = useState(true)

  const handleUserInfoClose = () => setShowUserInfo(false);

  const filterClose = () => setFilterShow(false);
  const trackingClose = () => setTrackingShow(false);

  useEffect(() => {
    categoryList();
    var years = Array.from(Array(new Date().getFullYear() - 2015), (_, i) => (i + 2016).toString())
    years = years.reverse()
    const list = []
    years?.forEach(async (year, index) => {
      const Year = {
        "label": year,
        "value": index + 1
      }
      list.push(Year)
      setYear(list)

    })
  }, [])

  useEffect(() => {
    if (purchaseHistoryListParams) {
      purchaseHistoryList(purchaseHistoryListParams)
    }
  }, [purchaseHistoryListParams])


  // RECEIVING PURCHASE HISTORY API RESPONSE
  useEffect(() => {
    if (purchaseHistoryResponse && purchaseHistoryResponse.apiStatus) {
      if (purchaseHistoryResponse.apiErrorCode == constants.STATUS_200 && purchaseHistoryResponse.apiResponse) {
        setPurchaseHistory(purchaseHistoryResponse.apiResponse.purchase_info)
        exportPurchaseHistory(purchaseHistoryListParams)
      }
      else {
        setDownloadUrl("")
        setPurchaseHistory([])
      }
      setLoad(false)
    }
  }, [purchaseHistoryResponse])

  // category listing response
  useEffect(() => {
    if (categoryListApiResponse && categoryListApiResponse.apiStatus) {
      if (categoryListApiResponse.apiErrorCode === constants.STATUS_200)
        if (categoryListApiResponse.apiResponse.category_info) {
          let categoryArray = [{ value: 0, label: "All" }]
          categoryListApiResponse.apiResponse.category_info.map((category) => {
            categoryArray.push({ value: category.category_id, label: category.category_name })
          })
          setCategories(categoryArray.filter(item => item.label !== "Coupons"))
        }
        else
          toast.error(categoryListApiResponse.apiMessage)
    }
  }, [categoryListApiResponse])

  // EXPORTING PURCHASE HISTORY API RESPONSE
  useEffect(() => {
    if (exportPurchaseHistoryResponse && exportPurchaseHistoryResponse.apiStatus) {
      if (exportPurchaseHistoryResponse.apiErrorCode == constants.STATUS_200 && exportPurchaseHistoryResponse.apiResponse) {
        setDownloadUrl(exportPurchaseHistoryResponse.apiResponse)
      }
      else {
        resetResponse(dispatch, "exportPurchaseHistory")
      }
    }
  }, [exportPurchaseHistoryResponse])


  useEffect(() => {
    const defaultClass = 'status-wrap d-flex flex-column align-items-center position-relative text-center';

    let acceptedClassName = defaultClass;
    let inTransitClassName = defaultClass;
    let deliveredClassName = defaultClass;
    if (userDetails.shippingStatus === constants.Shipped && userDetails.trackingNumber) {
      acceptedClassName = `${defaultClass} active`;
    }
    if (userDetails.shippingStatus === constants.InTransit) {
      acceptedClassName = `${defaultClass} active`;
      inTransitClassName = `${defaultClass} active`;
    }
    if (userDetails.shippingStatus === constants.Delivered) {
      acceptedClassName = `${defaultClass} active`;
      inTransitClassName = `${defaultClass} active`;
      deliveredClassName = `${defaultClass} active`;
    }

    setAcceptedClass(acceptedClassName);
    setInTransitClass(inTransitClassName);
    setDeliveredClass(deliveredClassName);
  }, [userDetails.shippingStatus, userDetails.trackingNumber]);


  // page change and state change
  const pageChange = (page) => {
    if (page) {
      setPurchaseHistoryListParams(purchaseHistoryListParams => ({ ...purchaseHistoryListParams, page: page }))
    }
  }

  //handle search changes
  const searchTextChange = (searchText) => {
    setPurchaseHistoryListParams(purchaseHistoryListParams => ({ ...purchaseHistoryListParams, search_key: searchText, page: 1 }))
  }

  //handle catagories changes
  const categoriesChange = (event) => {
    setPurchaseHistoryListParams(purchaseHistoryListParams => ({ ...purchaseHistoryListParams, category_id: event.value, page: 1 }))
  }

  const handleFilterMonthChanges = (event) => {
    setPurchaseHistoryFilters(purchaseHistoryFilters => ({ ...purchaseHistoryFilters, p_Month: event }))
  }
  const handleFilterYearChanges = (event) => {
    setPurchaseHistoryFilters(purchaseHistoryFilters => ({ ...purchaseHistoryFilters, p_Year: event }))
  }
  const handleFilterClear = (event) => {
    setPurchaseHistoryFilters(purchaseHistoryFilters => ({ ...purchaseHistoryFilters, p_Year: event.label }))
  }

  const clearPurchaseHistoryFilter = () => {
    setPurchaseHistoryFilters(intitalPurchaseHistoryFilters)
    setPurchaseHistoryListParams(initialParams)
    // setStartDateFilterError('')
  }


  // set filter options
  const filterPurchaseHistory = () => {
    setPurchaseHistoryListParams(purchaseHistoryListParams => ({
      ...purchaseHistoryListParams,
      page: 1,
      p_Month: purchaseHistoryFilters.p_Month.value ? purchaseHistoryFilters.p_Month.value : "",
      p_Year: purchaseHistoryFilters.p_Year.label ? purchaseHistoryFilters.p_Year.label : ""
    }))
    filterClose()
  }



  return (
    <InnerLayout>
      <Col className='action-wrap'>
        <Row>
          <Col lg='3' className='d-lg-flex align-items-lg-center'>
            <h1>{Strings.PurchaseHistory.Title}</h1>
          </Col>
          <Col lg='9' className='text-lg-end'>
            <form method="get" action={downloadUrl} target="_blank">
              <Button onClick={() => setFilterShow(true)}><Filter />{Strings.PurchaseHistory.Btn1}</Button>
              <Button disabled={downloadUrl === ""} type="submit" ><PdfIcn />{Strings.PurchaseHistory.Btn}</Button>
            </form>
          </Col>
        </Row>
      </Col>
      {load ? <Loader />
        :
        <>
          <Row className='search-wrap'>
            <Col lg='4' md='6'>
              <SearchInput onClick={searchTextChange} p_SearchKey={purchaseHistoryListParams.search_key} />
            </Col>
            <Col lg='8' md='6' className='d-lg-flex justify-content-lg-end'>
              <CustSelect
                placeholder="Category"
                noOptionsMessage={() => 'No Category!'}
                label={"Category"}
                options={categories}
                onChange={(e) => categoriesChange(e)}
              />
            </Col>
          </Row>
          <Col>
            <Table responsive className='users'>
              <thead>
                <tr>
                  <th>{Strings.PurchaseHistory.Th}</th>
                  <th>Date of<br />Purchase</th>
                  <th>{Strings.PurchaseHistory.Th2}</th>
                  <th>Price</th>
                  <th>Processing Fee<br />(2.9% + 30C)</th>
                  <th>{Strings.PurchaseHistory.Th9}</th>
                  <th>{Strings.PurchaseHistory.Th4}</th>
                  <th className='commission'>Shopvana<br />Commission </th>
                  <th className='text-center'>Refund<br/>Status</th>
                  <th className='text-center'>Shipping Status</th>
                </tr>
              </thead>
              <tbody>
                {purchaseHistoryResponse && purchaseHistoryResponse.apiStatus && purchaseHistory.length > 0 ? purchaseHistory.map((items, index) => (
                  <tr className={(items.delivery_option === 2  && items.label_url === null) ? 'highlight' :"" }>
                  <td className='user-photo'>
                    <div className='user-info'>
                      <Link onClick={() => { setShowUserInfo(true); setUserDetails(items) }} to="">{items.firstname}</Link>
                      {/* <span className='d-block'>{items.address1 ? items.address1.trim() + ", " : ""} {items.statename}</span> */}
                    </div>
                  </td>
                  <td>{dateToMMMDDYYYY(items.purchase_date)}</td>
                  <td className='offer-name' style={{ cursor: 'pointer' }} onClick={() => navigate("/productdetails", { state: { id: items.prod_id } })}><p className='m-0'>{items.prod_name} {items.qty > 1 ? "  x" + items.qty : ""}</p></td>
                  <td>{items.amount ? "$" + parseFloat(items.amount).toFixed(2) : "-"}</td>
                  <td className='commission'>{items.stripe_fees ? "$" + parseFloat(items.stripe_fees).toFixed(2) : "-"}</td>
                  <td className='amt'>${items.discountAmount ? items.discountAmount : 0}</td>
                  <td className='amt'>{items.discountedPrice ? "$" + parseFloat(items.discountedPrice).toFixed(2) : "-"}</td>
                  <td className='commission'>{(items.nearbuys_commission || items.nearbuys_commission === 0) ? "$" + parseFloat(items.nearbuys_commission).toFixed(2) : "-"}</td>
                  {items.refund_status === constants.STATUS_0 ? <td className='refund'><span className='not-avail text-center'><div className='not-avail'>NA</div></span></td> : ""}
                  {items.refund_status === constants.STATUS_1 ? <td className='refund'><Badge className='requested d-block'><a style={{ cursor: 'pointer' }} onClick={() => navigate("refund", { state: { id: items.purchase_id, refundStaus: items.refund_status } })}>Requested</a></Badge></td> : ""}
                  {items.refund_status === constants.STATUS_2 ? <td className='refund'><Badge className='initiated d-block'><a>Initiated</a></Badge></td> : ""}
                  {items.refund_status === constants.STATUS_4 ? <td className='refund'><Badge className='rejected d-block'><a>Rejected</a></Badge></td> : ""}
                  <td className='shipping-status'>
                  <>
                    {/* Show Setup Shipment button when delivery_option is 0, 1, null, or delivery_option is 2 
                    and label_url is null  */}
                    {(items.delivery_option === constants.STATUS_0 || 
                      items.delivery_option === constants.STATUS_1 || 
                      items.delivery_option === constants.STATUS_NULL || 
                      items.is_void_label === constants.STATUS_1 || 
                      (items.delivery_option === constants.STATUS_2 && items.label_url === constants.STATUS_NULL)) ? (
                        <>
                            {/* Display NA */}
                          {(items.delivery_option === constants.STATUS_0 || 
                            items.delivery_option === constants.STATUS_1 || 
                            items.delivery_option === constants.STATUS_NULL) && (
                              <div className='not-avail text-center'>NA</div>
                          )}
                          
                          {/* Only show 'Setup Shipment' button if NA is NOT displayed */}
                          {items.delivery_option === constants.STATUS_2 &&
                          items.label_url == constants.STATUS_NULL &&
                          items.paidStatus === constants.STATUS_1 ? (
                            // Payment Done But Label Not Generated
                            <Button
                              className="setup"
                              onClick={() =>
                                navigate("/setupshipment", {
                                  state: { id: items.purchase_id, isEdit: true },
                                })
                              }
                            >
                              Setup Shipment
                            </Button>
                          ) : items.delivery_option !== constants.STATUS_0 &&
                            items.delivery_option !== constants.STATUS_1 &&
                            items.delivery_option !== constants.STATUS_NULL ? (
                            // Generic Setup Shipment Button
                            <Button
                              className="setup"
                              onClick={() =>
                                navigate("/setupshipment", {
                                  state: { id: items.purchase_id, isEdit: false },
                                })
                              }
                            >
                              Setup Shipment
                            </Button>
                          ) : null}
                        </>
                      
                    ) : (
                      // Other conditions for displaying Delivered or Shipped buttons
                      <>
                        {/* Label Generated */}
                        {items.delivery_option === constants.STATUS_2 && items.shippingStatus === constants.Label_Generated && items.label_url != null && (
                          <Button className='setup'  onClick={() => navigate("/setupshipment", { state: { id: items.purchase_id, isEdit: true } })}>
                            {items.shippingStatus}
                          </Button>
                        )}
                        
                        
      

                        {/* //Accepted or shipped */}
                       {items.delivery_option === constants.STATUS_2  && items.shippingStatus === constants.Shipped && items.label_url != null && (
                       
                          <Button className='shipped'  onClick={() => {setTrackingShow(true); setUserDetails(items)}}>
                           {items.shippingStatus}
                          </Button>
                        )}
                        {/* Intransit */}
                        {items.delivery_option === constants.STATUS_2  && items.shippingStatus === constants.InTransit && items.label_url != null && (
                          <Button className='shipped'  onClick={() => {setTrackingShow(true); setUserDetails(items)}}>
                           {items.shippingStatus}
                          </Button>
                        )}
                        
                          {/* Delivered */}
                        {items.delivery_option === constants.STATUS_2  && items.shippingStatus === constants.Delivered && items.label_url != null && (
                          <Button className='delivered'   onClick={() => {setTrackingShow(true); setUserDetails(items)}}>
                           {items.shippingStatus}
                          </Button>
                        )}
                      </>
                    )}
                  </>
                  {/* avoid button */}
                 {/* {items.is_void_label === constants.STATUS_1 ? <ICShipmentInfo/>  : ""} */}
                  </td>
                </tr>)) : ""}
              </tbody>
            </Table>
            {purchaseHistoryResponse && purchaseHistoryResponse.apiStatus && purchaseHistory.length > 0 ? "" : <Col className="text-center mt-5">{alerts.NO_PURCHASE_HISTORY_AVAILABLE}</Col>}
            {
              purchaseHistoryResponse.apiStatus && purchaseHistoryResponse.apiErrorCode == constants.STATUS_200 && purchaseHistoryResponse.apiResponse && purchaseHistoryResponse.apiResponse.pagination_info.total_records ?
                <Col className='d-flex justify-content-center paginate-wrap'>
                  <span className='records'>
                    {Strings.Pagination.Label} &nbsp;
                    {((purchaseHistoryListParams.page - 1) * purchaseHistoryResponse.apiResponse.pagination_info.per_page) + 1
                    }
                    &nbsp; {Strings.Pagination.Label1} &nbsp;
                    {((purchaseHistoryListParams.page - 1) * purchaseHistoryResponse.apiResponse.pagination_info.per_page) + purchaseHistoryResponse.apiResponse.purchase_info.length}
                    &nbsp; {Strings.Pagination.Label2} &nbsp;
                    {purchaseHistoryResponse.apiResponse.pagination_info.total_records}
                    &nbsp; {Strings.Pagination.Label3}

                  </span>

                  <Paginate
                    totalRecords={purchaseHistoryResponse.apiResponse.pagination_info.total_records}
                    currentPage={purchaseHistoryResponse.apiResponse.pagination_info.current_page}
                    perPage={purchaseHistoryResponse.apiResponse.pagination_info.per_page}
                    onClick={pageChange}
                  />
                </Col> : ""
            }
          </Col>
          {/* Tracking Modal */}
          <Modal show={trackingshow} size="lg" onHide={trackingClose} centered backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Shipment Status</Modal.Title>
            </Modal.Header>
            <Modal.Body className='shipment-dtls'>
              <div className='shipment-info'>
                <span>{userDetails?.carrier_code}</span>
                <p>Tracking ID: {userDetails?.trackingNumber}</p>
              </div>
              <div className='shipment-status d-flex w-100'>
                <div className={acceptedClass}>
                  <div className='step'></div>
                  <div className='status'>Accepted</div>
                  {/* <div className='date'>Aug 26, 2022</div> */}
                </div>
                <div className={inTransitClass}>
                  <div className='step'></div>
                  <div className='status'>In Transit</div>
                  {/* <div className='date'>Aug 28, 2022</div> */}
                </div>
                <div className={deliveredClass}>
                  <div className='step'></div>
                  <span className='status'>Delivered</span>
                  {/* <div className='date'>Date</div> */}
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal show={filtershow} size="md" onHide={filterClose} centered backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>{Strings.PurchaseHistory.Modal.Title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='filter-wrap'>
              <CustSelectLabel placeholder={Strings.PurchaseHistory.Modal.Label1} label={Strings.PurchaseHistory.Modal.Label1} options={monthOptions} value={purchaseHistoryFilters.p_Month} onChange={handleFilterMonthChanges} />
              <CustSelectLabel placeholder={Strings.PurchaseHistory.Modal.Label2} label={Strings.PurchaseHistory.Modal.Label2} options={year} value={purchaseHistoryFilters.p_Year} onChange={handleFilterYearChanges} />
            </Modal.Body>
            <Modal.Footer className='justify-content-start mt-3'>
              <Button onClick={() => clearPurchaseHistoryFilter()} variant="outline-primary">{Strings.PurchaseHistory.Modal.Btn1}</Button>
              <Button onClick={() => filterPurchaseHistory()} variant="primary">{Strings.PurchaseHistory.Modal.Btn2}</Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showUserInfo} onHide={handleUserInfoClose} size="xl" centered backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>{Strings.PurchaseHistory.Modal.UserInfo.Title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <BusinessDetails icon={<User />} label={Strings.PurchaseHistory.Modal.UserInfo.Label1} value={userDetails.AppUser ? userDetails.AppUser : ""} />
                <BusinessDetails icon={<Phone />} label={Strings.PurchaseHistory.Modal.UserInfo.Labe12} value={userDetails.phoneno ? formatPhoneNumber(userDetails.phoneno) : ""} />
                <BusinessDetails icon={<Mail />} label={Strings.PurchaseHistory.Modal.UserInfo.Label3} value={userDetails.email ? userDetails.email : ""} />
                <BusinessDetails icon={<Address />} label={Strings.PurchaseHistory.Modal.UserInfo.Label4}
                  value={
                    (userDetails.address1 ? userDetails.address1.trim() + ',  ' : ' ') +
                    (userDetails.address2 ? userDetails.address2.trim() + ',  ' : ' ') +
                    (userDetails.city ? userDetails.city.trim() + ',  ' : ' ') +
                    (userDetails.statename ? userDetails.statename.trim() + ',  ' : ' ') +
                    (userDetails.zip ? userDetails.zip.trim() + ' ' : ' ')} />
              </Row>
            </Modal.Body>
            <Modal.Footer className='justify-content-start'>
            </Modal.Footer>
          </Modal>
        </>
      }
    </InnerLayout>
  );
}
export default PurchaseHistory;
