import React, { useState } from "react";
import "./Plan.scss";
import { Button, Col, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  _vendorCreateSubscription,
  _vendorUpgradeSubscription,
  _vendorDowngradeSubscription,
  _vendorExpireSubscription
} from "../actions/VendorAction";
import Strings from "../assets/strings/Strings.json";
import * as constants from '../utils/constants';
import * as alerts from '../utils/Messages'
import { SubscriptionPlans } from "../utils/Enum";

const Plan = (props) => {
  const dispatch = useDispatch();
  let dataTopList;
  let dataBottomList
  const Data = props.data;
  const featureData = Data.subscription_features
  const setData = props.setPlanData;
  const subscriptionInfo = props.subscriptionInfo;
  const setLoading = props.setLoadingModal
  const cardDetails = props.cardDetails
  const subscribed = props.subscribed
  const firstSubscribed = props.firstSubscribed
  const setCurrentTab = props.setCurrentTab
  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // addSubscription start
  // addSubscription ends
  const createSubscription = (params) =>
    dispatch(_vendorCreateSubscription(params));
  // addSubscription ends
  // addSubscription ends
  const upgradeSubscription = (params) =>
    dispatch(_vendorUpgradeSubscription(params));
  // addSubscription ends
  // addSubscription ends
  const downgradeSubscription = (params) =>
    dispatch(_vendorDowngradeSubscription(params));
  // addSubscription ends
  // addSubscription ends
  const expireSubscription = (params) =>
    dispatch(_vendorExpireSubscription(params));
  // addSubscription ends

  dataTopList = featureData.slice(0, 6);
  dataBottomList = featureData.slice(6, featureData.length);


  const [upgradePopUp, setUpgradePopUp] = useState(false)
  const [confirmPopUp, setConfirmPopUp] = useState(false)


  const showPlanUpgradePopUp = () => {
    if (Data.plan_id < subscriptionInfo?.subscription_type  && props.subscriptionStatus !== constants.STATUS_EXPIRED) {
      setUpgradePopUp(true)
    } else if (subscriptionInfo && subscriptionInfo.subscription_type === null){
      onUpgrade(Data.plan_id,Data.lookupKey)
    }
    else{
      setConfirmPopUp(true)
    } 
  }

  const onUpgrade = (planId,lookupKey) => {
    setConfirmPopUp(false)
    if (cardDetails) {
      setData((data) => ({
        ...data,
        plan_id: planId,
        is_upgrade: subscriptionInfo.subscription_type === null ? 0 : subscribed && !firstSubscribed ? 3 : 
        subscriptionInfo && (planId > subscriptionInfo?.subscription_type) ? 2 : 1,
        lookupKey:lookupKey,
      }));
      if (subscriptionInfo.subscription_type === null) {
        createSubscription({
          is_upgrade: 0,
          subscription_type: planId,
          timeZone: timeZone,
          lookupKey:lookupKey,
        });
      } else if (subscribed && !firstSubscribed) {
        expireSubscription({
          is_upgrade: 3,
          subscription_type: planId,
          timeZone: timeZone,
          lookupKey:lookupKey,
        });
      } else if (subscriptionInfo && planId > subscriptionInfo?.subscription_type) {
        upgradeSubscription({
          is_upgrade: 2,
          subscription_type: planId,
          timeZone: timeZone,
          lookupKey:lookupKey,
        });
      } else {
        downgradeSubscription({
          is_upgrade: 1,
          subscription_type: planId,
          timeZone: timeZone,
          lookupKey:lookupKey,
        });
      }
      setLoading(true)
    } else {
      setCurrentTab('payment_method')
    }
    setUpgradePopUp(false)
  };
  return (
    <>
      <Col md="12" lg="6" xl="3" className={"plan-wrap"}>
        <div className={subscriptionInfo && subscriptionInfo.subscription_type === Data.plan_id && !subscribed ? props.className + " " + "active" : props.className}>
          <div className="plan-header text-center">
            {Data && Data.plan_name ? Data.plan_name : ""}
          </div>
          <div className="plan-dtl-outer">
            <div className="plan-price">
              <h4>${ Data.plan_amount && parseInt(Data?.plan_amount)} 
                <span>/ 
                 {Data?.plan_id == SubscriptionPlans.Monthly ? Strings.Profile.Subscription.Label10 : Strings.Profile.Subscription.Label6} 
                </span>
              </h4>

              {subscriptionInfo &&
                subscriptionInfo.subscription_type === Data.plan_id &&
                !subscribed ? (
                <Button variant="primary" onClick={props.currentPlan}>
                  {Strings.Profile.Subscription.Btn}
                </Button>
              ) : subscriptionInfo &&
                subscriptionInfo.upcoming_type === Data.plan_id &&
                !subscribed ? (
                <Button variant="primary" onClick={props.currentPlan}>
                  {Strings.SubscriptionInfo.Btn4}
                </Button>
              ) : (
                <Button
                  disabled={
                    subscriptionInfo &&
                    subscriptionInfo.upcoming_type !== 0 &&
                    subscriptionInfo.upcoming_type !== Data.plan_id
                  }
                  onClick={() =>
                    showPlanUpgradePopUp()
                    //onUpgrade(Data.price_id, Data.plan_id, Data.plan_amount)
                  }
                >
                  {" "}
                  {subscriptionInfo && subscriptionInfo.subscription_type === null
                    ? Data.plan_id === SubscriptionPlans.Monthly 
                      ? Strings.Profile.Subscription.Btn3 
                      : Strings.Profile.Subscription.Btn1
                    : !subscribed
                      ? Strings.SubscriptionInfo.Btn1
                      : Strings.Profile.Subscription.Btn2}
                </Button>
              )}
            </div>
            <div className="plan-body">
              <div className="top">
                <ul>
                  {Data &&
                    dataTopList &&
                    dataTopList.map((data, key) => {
                      return <li className="active">{data?.feature_text}</li>;
                    })}            
                </ul>
              </div>
              <div className="bottom">
                <ul>
                  {Data &&
                    dataBottomList &&
                    dataBottomList.map((data, key) => {
                      return <li className="active">{data?.feature_text}</li>;
                    })}

                  {Data?.plan_id == SubscriptionPlans.Basic ? (
                    <>
                      <li className="plan-inactive">Add Proxy Address</li>
                    </>
                  ) : (
                    ""
                  )}
                </ul>              
              </div>
            </div>
          </div>
        </div>
      </Col>

      <Modal
        show={upgradePopUp}
        onHide={() => setUpgradePopUp(false)}
        size="l"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <h5 className="text-center">
           {Data.plan_id === SubscriptionPlans.Basic && alerts.SUBSCRIPTION_DOWNGRADE_TO_TIER1}
           {Data.plan_id === SubscriptionPlans.Advanced && alerts.SUBSCRIPTION_DOWNGRADE_TO_TIER2}
           {Data.plan_id === SubscriptionPlans.Monthly && alerts.SUBSCRIPTION_DOWNGRADE_TO_TIER4}
          </h5>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="outline-primary"
            onClick={() => setUpgradePopUp(false)}
          >
            {Strings.Profile.PaymentMethod.Btn}
          </Button>
          <Button variant="primary"
            onClick={() => onUpgrade( Data.plan_id,Data.lookupKey)}
          >
            {Strings.Profile.PaymentMethod.Btn3}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={confirmPopUp}
        onHide={() => setConfirmPopUp(false)}
        size="l"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <h5 className="text-center">
            Are you sure you want to proceed with the payment?
          </h5>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="outline-primary"
            onClick={() => setConfirmPopUp(false)}
          >
            {Strings.Profile.PaymentMethod.Btn}
          </Button>
          <Button variant="primary"
            onClick={() => onUpgrade(Data.plan_id,Data.lookupKey)}
          >
            {Strings.Profile.PaymentMethod.Btn3}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

};

export default Plan;
